import { BASE_URL } from "src/utils/config";
import { IGetAllQueryParams } from "../../types/dispense";

export const getAllDispenses = (institutionId: string, query: IGetAllQueryParams) =>
  `${BASE_URL}/api/v1/dispense/all/${institutionId}?page=${query.page}&limit=${
    query.limit
  }&startDate=${query.startDate || ""}&endDate=${query.endDate || ""}&machineId=${
    query.machineId || ""
  }&status=${query.status || ""}&transactionType=${query.transactionType || ""}`;

export const getSuccessfulDispenseStats = (institutionId: string, start: string, end: string) =>
  `${BASE_URL}/api/v1/dispense/stats/institution/${institutionId}/${start}/${end}`;
