import React from "react";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const { Option } = Select;

interface IProps {
  name?: string;
  placeholder?: string;
  size?: SizeType;
  options: string[];
  value?: number;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (name: string | undefined, key: number, value: string) => void;
}

const SelectComponent: React.FC<IProps> = props => {
  const {
    name,
    size,
    options,
    value,
    onChange,
    onFocus,
    placeholder,
    disabled
  } = props;
  const val = value !== undefined ? options[value] : undefined;
  const children = [];

  for (let i = 0; i < options.length; i++) {
    children.push(<Option key={i} value={options[i]}>{options[i]}</Option>);
  }

  function handleChange(value: string, option: any) {
    if (onChange) onChange(name, parseInt(option.key), value);
  }

  return (
    <Select
      size={size || "large"}
      value={val}
      onChange={handleChange}
      onFocus={onFocus}
      disabled={disabled}
      placeholder={placeholder}
      style={{ width: "100%" }}>
      {children}
    </Select>
  );
};

export default SelectComponent;