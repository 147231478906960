import styled from "styled-components";
import React from "react";

const MyDiv = styled.div<IProps>`
  display: ${({ display }) => display};
  flex-flow: ${({ flexFlow, display }) =>
    display ? (flexFlow === "column" ? "column" : "row") : null};
  flex-wrap: ${({ flexWrap }) => flexWrap};

  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding || ""};
  margin: ${({ margin }) => margin || ""};

  width: ${({ width }) => width || ""};
  height: ${({ height }) => height || ""};

  justify-content: ${({ justifyContent }) => justifyContent || null};
  justify-items: ${({ justifyItems }) => justifyItems || null};

  align-content: ${({ alignContent }) => alignContent || null};
  align-items: ${({ alignItems }) => alignItems || null};
`;

interface IProps {
  id?: string;
  display?: "flex" | "block";
  flexFlow?: string;
  flex?: number | string;
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  background?: string;
  justifyContent?: string;
  justifyItems?: string;
  alignContent?: string;
  alignItems?: string;
  flexWrap?: string;
  onClick?: any;
}

const Container: React.FC<IProps> = (props) => {
  const {
    display,
    flexFlow,
    flex,
    padding,
    margin,
    width,
    height,
    background,
    justifyContent,
    justifyItems,
    alignContent,
    alignItems,
    flexWrap,
    id,
    onClick,
  } = props;
  return (
    <MyDiv
      id={id}
      flex={flex}
      display={display}
      flexFlow={flexFlow}
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      background={background}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignContent={alignContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      onClick={onClick}
    >
      {props.children}
    </MyDiv>
  );
};

export default Container;
