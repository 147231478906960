export const INSTITUTION_CATEGORY = {
  SOCIAL: "SOCIAL" as const,
  COMMERCIAL: "COMMERCIAL" as const,
};

export const institutionCategory = Object.values(INSTITUTION_CATEGORY);
export type TInstitutionCategory = typeof institutionCategory[number];

export interface IInstitution {
  _id: string;
  name: string;
  type?: string;
  organizationId: string;
  category: TInstitutionCategory;
  address: string;
  limitation?: {
    daily: number;
    weekly: number;
    monthly: number;
  };
  refill?: {
    refillMonthly: boolean;
    count: number;
    onDemandAllowed: boolean;
  };
  // pointsEnabled: boolean;
}

export interface IInstitutionCreate {
  name: string;
  type?: string;
  organizationId?: string;
  category: TInstitutionCategory;
  address: string;
  limitation?: {
    daily?: number;
    weekly?: number;
    monthly?: number;
  };
  refill?: {
    refillMonthly: boolean;
    count: number;
    onDemandAllowed: boolean;
  };
  // pointsEnabled: boolean;
}

export interface IInstitutionUpdate {
  name?: string;
  type?: string;
  organizationId?: string;
  category?: TInstitutionCategory;
  address?: string;
  limitation?: {
    daily?: number;
    weekly?: number;
    monthly?: number;
  };
  refill?: {
    refillMonthly: boolean;
    count: number;
    onDemandAllowed: boolean;
  };
  // pointsEnabled: boolean;
}
