import { Avatar } from "antd";
import React from "react";

interface IProps {
  src?: string;
  size?: number | "small" | "large" | "default";
  className?: string;
}

const MyAvatar: React.FC<IProps> = (props) => {
  const {
    src,
    size,
    className
  } = props;

  return (
    <Avatar
      size={size}
      src={src}
      className={className}
    />
  );
};

export default MyAvatar;