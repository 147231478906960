import React, { ElementType } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import { TAdminType } from "src/types/admin";

interface IProps {
  component: ElementType;
  layout: ElementType;
  adminTypes: (TAdminType | undefined)[];
  exact: boolean;
  path: string;
}

const PrivateRoute: React.FC<IProps> = ({
  component: Component,
  layout: Layout,
  adminTypes,
  ...rest
}) => {
  const { adminAuth } = useAdminAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        adminAuth?.isAuthenticated && adminTypes.includes(adminAuth.admin?.type) ? (
          // {Component}
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
};

export { PrivateRoute };
