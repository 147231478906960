import styled from "styled-components";
import React from "react";
import { Writing4 } from "../texts/writing";

const MyDiv = styled.div<{
  color: "successLight" | "dangerLight" | "warningLight" | undefined;
}>`
  width: max-content;
  padding: 0.1em 1em;
  border-radius: 15px;
  background: ${({ color, theme }) => {
    return color ? (color ? theme.colors[color] : null) : null;
  }};
`;

interface IProps {
  status?: "success" | "error" | "warning";
  label: string;
  className?: string;
}

const Status: React.FC<IProps> = (props) => {
  const { status, className, label } = props;

  let color: "successLight" | "dangerLight" | "warningLight" | undefined;

  switch (status) {
    case "success":
      color = "successLight";
      break;
    case "error":
      color = "dangerLight";
      break;
    case "warning":
      color = "warningLight";
      break;
  }

  return (
    <MyDiv className={className} color={color}>
      <Writing4>{label}</Writing4>
    </MyDiv>
  );
};

export default Status;
