export const ADMIN_TYPE = {
  SUPER: "SUPER" as const,
  ORGANIZATION: "ORGANIZATION" as const,
  INSTITUTION: "INSTITUTION" as const,
};

export const adminType = Object.values(ADMIN_TYPE);
export type TAdminType = typeof adminType[number];

export interface IAdmin {
  _id: string;
  username: string;
  avatar: string;
  type: TAdminType;
  organizationId?: string;
  institutionId?: string;
  // verified: boolean;
}

export interface ITokens {
  accessToken: string;
}

export interface IAuth {
  admin: IAdmin;
  tokens: ITokens;
  isAuthenticated: boolean | null;
}

export interface IOrganizationAdmin extends IAdmin {
  organizationId: string;
  password: string;
}

export interface IInstitutionAdmin extends IOrganizationAdmin {
  institutionId?: string;
}

export interface ICreateAdmin {
  username: string;
  password: string;
}
