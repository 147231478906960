import React from "react";
import { Tooltip } from "antd";

interface IProps {
  tooltip: string;
  children: React.ReactElement;
}

const MyTooltip: React.FC<IProps> = (props) => {
  return (
    <Tooltip title={props.tooltip}>
      {props.children}
    </Tooltip>
  );
};

export default MyTooltip;