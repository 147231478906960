import React from "react";
import styled from "styled-components";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

const MyRadio = styled(Radio) <IProps>`
  transform: ${({ size }) =>
    (
      size === "small" ? null
        : size === "large" ? "scale(1.625)"
          : "scale(1.375)"
    )};
  margin-left: ${({ size }) =>
    (
      size === "small" ? null :
        size === "middle" ? "0.5em" : "1.2em"
    )};
`;

interface IProps {
  size?: "small" | "middle" | "large";
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  value?: string | number | boolean;
  name?: string;
  className?: string;
  onChange?: (e: RadioChangeEvent) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const RadioInput: React.FC<IProps> = (props) => {
  const { size, disabled, checked, defaultChecked, value, name,
    className, onChange, onFocus } = props;
  return (
    <MyRadio
      size={size}
      disabled={disabled}
      checked={checked}
      defaultChecked={defaultChecked}
      value={value}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
      className={className}
    >
      {props.children}
    </MyRadio>
  );
};

export default RadioInput;