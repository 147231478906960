import React from "react";
import styled from "styled-components";
import { AutoCompleteTextfield } from "../../atoms/inputs";
import { Label1, Label2, Label3, Label4 } from "../../atoms/texts/label";
import { Message } from "../texts/message";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const TextFieldContainer = styled.div`
  display:flex;
  flex-direction:column;
  padding:0;
`;

const LabelContainer = styled.div`
  display:flex;
  padding-bottom:0.5em;
`;

const FieldContainer = styled.div`
  display:flex;
`;

const ErrorContainer = styled.div`
   padding-top:0.2em;
`;

interface IProps {
  open?: boolean;
  size?: SizeType;
  className?: string;
  labelText?: string;
  labelSize?: number;
  errorMessage?: string;
  disabled?: boolean;
  name?: string;
  options: string[];
  placeholder?: string;
  value?: string;
  onChange?: (name: string | undefined, key: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onSelect?: (value: string) => void;
}

const AutoCompleteTextForm: React.FC<IProps> = (props) => {
  const { className, labelText, labelSize, errorMessage, value, disabled, open,
    size, options, placeholder, name, onChange, onFocus, onBlur, onSelect } = props;

  const Label = labelSize === 1 ? Label1
    : labelSize === 3 ? Label3
      : labelSize === 4 ? Label4
        : Label2;

  return (
    <TextFieldContainer className={className}>
      {labelText ?
        <LabelContainer>
          <Label>{labelText}</Label>
        </LabelContainer>
        : null
      }
      <FieldContainer>
        <AutoCompleteTextfield
          open={open}
          size={size}
          options={options}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSelect={onSelect}
        />
      </FieldContainer>
      {
        errorMessage ?
          <ErrorContainer >
            <Message type="error" message={errorMessage} />
          </ErrorContainer>
          : null
      }
    </TextFieldContainer>
  );
};

export default AutoCompleteTextForm; 