/* eslint-disable indent */
import React from "react";
import styled from "styled-components";
import { TextArea } from "../../atoms/inputs";
import { Label1, Label2, Label3, Label4 } from "../../atoms/texts/label";
import { Message } from "../texts/message";

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const LabelContainer = styled.div`
  display: flex;
  padding-bottom: 0.5em;
`;

const FieldContainer = styled.div`
  display: flex;
`;

const ErrorContainer = styled.div`
  padding-top: 0.2em;
`;

interface IMyProps {
  labelText?: string;
  labelSize?: number;
  errorMessage?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  rows?: number;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: () => void;
  allowClear?: boolean;
  className?: string;
}

const MyTextAreaForm: React.FC<IMyProps> = (props) => {
  const {
    className,
    maxLength,
    labelText,
    labelSize,
    errorMessage,
    defaultValue,
    value,
    disabled,
    placeholder,
    id,
    name,
    rows,
    onChange,
    onFocus,
    onPressEnter,
    allowClear,
  } = props;

  const Label =
    labelSize === 1
      ? Label1
      : labelSize === 3
      ? Label3
      : labelSize === 4
      ? Label4
      : Label2;

  return (
    <TextAreaContainer className={className}>
      <LabelContainer>
        <Label>{labelText}</Label>
      </LabelContainer>
      <FieldContainer>
        <TextArea
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          name={name}
          rows={rows}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          onPressEnter={onPressEnter}
          allowClear={allowClear}
        />
      </FieldContainer>
      {errorMessage ? (
        <ErrorContainer>
          <Message type="error" message={errorMessage} />
        </ErrorContainer>
      ) : null}
    </TextAreaContainer>
  );
};

export default MyTextAreaForm;
