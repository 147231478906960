import React, { Context, createContext, useCallback, useContext, useState } from "react";
import { ICommonApiError } from "src/api/apiRequest";
import { handlePrivateApiError } from "src/api/errorHandlers";
import {
  createRFIDApi,
  deleteRFIDApi,
  getAllRFIDApi,
  getRFIDByIdApi,
  updateRFIDApi,
} from "src/api/rfid/rfidApi";
import { IRFID, IRFIDCreate, IRFIDUpdate } from "src/types/rfid";
import { useAdminAuthContext } from "../admin/AuthContext";
import { useInstitutionsContext } from "../institution/InstitutionsContext";
import { showErrorToastAction, showToastAction } from "../toast";

interface IRFIDContextProps {
  rfidLoading: boolean;
  rfidChangeLoading: boolean;
  rfids: IRFID[];
  getAllRFIDApiAction: (institutionId: string) => Promise<void>;
  getRFIDByIdApiAction: (institutionId: string, rfidId: string) => Promise<IRFID | null>;
  createRFIDApiAction: (institutionId: string, createData: IRFIDCreate) => Promise<void>;
  updateRFIDApiAction: (
    institutionId: string,
    rfidId: string,
    editData: IRFIDUpdate
  ) => Promise<void>;
  deleteRFIDApiAction: (institutionId: string, rfidId: string) => Promise<void>;
}

export const RFIDContext = createContext<IRFIDContextProps | undefined>(
  undefined
) as Context<IRFIDContextProps>;

const RFIDProvider: React.FC = (props: any) => {
  const [rfidLoading, setRFIDLoading] = useState<boolean>(false);
  const [rfidChangeLoading, setRFIDChangeLoading] = useState<boolean>(false);
  const [rfids, setRFIDs] = useState<IRFID[]>([]);

  // Log out action from Auth context to use in privateErrors
  const { logoutAdminApiAction } = useAdminAuthContext();
  const { currentInstitution } = useInstitutionsContext();

  /**
   * RFID Api Actions
   * Get all
   * Get By ID
   * Crete
   * Update
   * Delete
   */
  const getAllRFIDApiAction = useCallback(
    (institutionId: string) => {
      return new Promise<void>((resolve) => {
        if (currentInstitution?._id !== institutionId) {
          setRFIDLoading(true);
          getAllRFIDApi(institutionId)
            .then((res) => {
              setRFIDs(res.data);
            })
            .catch((err: ICommonApiError) => {
              const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
              showErrorToastAction({
                message: data?.message || error || "Failed to fetch rfids",
              });
            })
            .finally(() => {
              setRFIDLoading(false);
              resolve();
            });
        }
      });
    },
    [logoutAdminApiAction, currentInstitution]
  );

  const getRFIDByIdApiAction = (institutionId: string, rfidId: string) => {
    return new Promise<IRFID | null>((resolve) => {
      getRFIDByIdApi(institutionId, rfidId)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to fetch rfid",
          });
          resolve(null);
        });
    });
  };

  const createRFIDApiAction = (institutionId: string, createData: IRFIDCreate) => {
    return new Promise<void>((resolve) => {
      setRFIDChangeLoading(true);
      createRFIDApi(institutionId, createData)
        .then((res) => {
          setRFIDs((prev) => [...prev, res.data]);
          showToastAction({ type: "success", message: "RFID created successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to create rfid",
          });
        })
        .finally(() => {
          setRFIDChangeLoading(false);
          resolve();
        });
    });
  };

  const updateRFIDApiAction = (institutionId: string, rfidId: string, editData: IRFIDUpdate) => {
    return new Promise<void>((resolve) => {
      setRFIDChangeLoading(true);
      updateRFIDApi(institutionId, rfidId, editData)
        .then((res) => {
          setRFIDs((prev) => {
            const index = prev.findIndex((rfid) => rfid._id === rfidId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
          showToastAction({ type: "success", message: "RFID updated successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to update rfid",
          });
        })
        .finally(() => {
          setRFIDChangeLoading(false);
          resolve();
        });
    });
  };

  const deleteRFIDApiAction = (institutionId: string, rfidId: string) => {
    return new Promise<void>((resolve) => {
      setRFIDChangeLoading(true);
      deleteRFIDApi(institutionId, rfidId)
        .then(() => {
          setRFIDs((prev) => {
            const filteredData = prev.filter((rfid) => rfid._id !== rfidId);
            return [...filteredData];
          });
          showToastAction({ type: "success", message: "RFID deleted successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to delete rfid",
          });
        })
        .finally(() => {
          setRFIDChangeLoading(false);
          resolve();
        });
    });
  };

  const contextProps = {
    rfidLoading,
    rfidChangeLoading,
    rfids,
    getAllRFIDApiAction,
    getRFIDByIdApiAction,
    createRFIDApiAction,
    updateRFIDApiAction,
    deleteRFIDApiAction,
  };

  return <RFIDContext.Provider value={contextProps}>{props.children}</RFIDContext.Provider>;
};

export default RFIDProvider;

export const useRFIDContext = () => {
  const context = useContext(RFIDContext);
  if (!context) {
    throw new Error("useRFIDContext must be used within a RFIDProvider");
  }
  return context;
};
