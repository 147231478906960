import { CaretDownFilled } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Loading } from "src/components/atoms/displays";
import { Container } from "src/components/atoms/grid";
import { Writing3 } from "src/components/atoms/texts/writing";
import styled from "styled-components";
import { useDispenseContext } from "../../../contexts/dispense/DispenseContext";
import { useMachineContext } from "../../../contexts/machine/MachineContext";
import {
  IGetAllQueryParams,
  TRANSACTION_TYPE,
  getAllQueryStatus,
  getAllQueryTransactionTypes,
} from "../../../types/dispense";
import { Gap } from "../../atoms/spaces";
import { DateRangepicker } from "../../molecules/dates";
import StyledPagination from "../common/StyledPagination";
import { ListDiv, ListItem, TBody, THead, THeader } from "./common";

const DispenseStatusText = styled(Writing3)<{ success?: boolean }>`
  color: ${({ theme, success }) =>
    success ? theme.colors.successColor : theme.colors.dangerColor};
`;

const MenuItem = styled(Menu.Item)<{ active?: any }>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.borderColor : theme.colors.backgroundColor};
  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.borderColor : theme.colors.gray4};
  }
`;

interface IProps {
  pageSize: number;
  institutionId: string;
}

const RFID: React.FC<IProps> = ({ pageSize, institutionId }) => {
  const { dispenses, count, dispensesLoading, getAllDispensesApiAction } = useDispenseContext();
  const { machines } = useMachineContext();

  const [apiParams, setApiParams] = useState<IGetAllQueryParams>({
    page: 1,
    limit: pageSize,
    startDate: moment().subtract(1, "months").startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
    status: "ALL",
    transactionType: "ALL",
    machineId: undefined,
  });
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(1, "months"),
    end: moment(),
  });

  useEffect(() => {
    getAllDispensesApiAction(institutionId, apiParams);
  }, [getAllDispensesApiAction, institutionId, apiParams]);

  const handleChangePage = (pageNo: number) => {
    setApiParams((prev) => ({ ...prev, page: pageNo }));
  };

  const onDateRangeChange = (name: string, value: any) => {
    if (!name) return;
    setDateRange((prev) => ({ ...prev, [name]: value }));
    if (name === "start") {
      setApiParams((prev) => ({ ...prev, startDate: value?.toDate() }));
    } else {
      setApiParams((prev) => ({ ...prev, endDate: value?.toDate() }));
    }
  };

  return (
    <Container>
      <DateRangepicker
        name="dateRange"
        labelText="Select a range"
        values={[dateRange.start, dateRange.end]}
        onChange={(_name, newDate) => {
          if (newDate) {
            onDateRangeChange("start", newDate[0]);
            onDateRangeChange("end", newDate[1]);
          }
        }}
      />
      <Container>
        <THeader columns={6}>
          <Dropdown
            trigger={["click"]}
            overlay={() => (
              <Menu>
                <MenuItem
                  active={apiParams.machineId === undefined ? "true" : undefined}
                  onClick={() => setApiParams((prev) => ({ ...prev, machineId: undefined }))}
                >
                  <Writing3>All</Writing3>
                </MenuItem>
                {machines.map((mach) => (
                  <MenuItem
                    key={mach._id}
                    active={apiParams.machineId === mach._id ? "true" : undefined}
                    onClick={() => setApiParams((prev) => ({ ...prev, machineId: mach._id }))}
                  >
                    <Writing3>{mach.physicalId}</Writing3>
                  </MenuItem>
                ))}
              </Menu>
            )}
          >
            <THead clickable="true">
              <Writing3>Machine</Writing3>
              <CaretDownFilled />
            </THead>
          </Dropdown>

          <Dropdown
            trigger={["click"]}
            overlay={() => (
              <Menu>
                {getAllQueryTransactionTypes.map((transaction) => (
                  <MenuItem
                    key={transaction}
                    active={apiParams.transactionType === transaction ? "true" : undefined}
                    onClick={() =>
                      setApiParams((prev) => ({ ...prev, transactionType: transaction }))
                    }
                  >
                    <Writing3>{transaction}</Writing3>
                  </MenuItem>
                ))}
              </Menu>
            )}
          >
            <THead clickable="true">
              <Writing3>Payment Type</Writing3>
              <CaretDownFilled />
            </THead>
          </Dropdown>

          <THead>
            <Writing3>RFID</Writing3>
          </THead>
          <THead>
            <Writing3>Time</Writing3>
          </THead>

          <Dropdown
            trigger={["click"]}
            overlay={() => (
              <Menu>
                {getAllQueryStatus.map((stat) => (
                  <MenuItem
                    key={stat}
                    active={apiParams.status === stat ? "true" : undefined}
                    onClick={() => setApiParams((prev) => ({ ...prev, status: stat }))}
                  >
                    <Writing3>{stat}</Writing3>
                  </MenuItem>
                ))}
              </Menu>
            )}
          >
            <THead clickable="true">
              <Writing3>Status</Writing3>
              <CaretDownFilled />
            </THead>
          </Dropdown>
          <THead>
            <Writing3>Failure Reason</Writing3>
          </THead>
        </THeader>

        {!dispensesLoading ? (
          <>
            <TBody>
              {dispenses.map((dispense) => (
                <ListItem key={dispense._id} columns={6}>
                  <ListDiv>
                    <Writing3>{dispense.machineId}</Writing3>
                  </ListDiv>
                  <ListDiv>
                    <Writing3>
                      {dispense.transactionType}
                      {dispense.transactionType === TRANSACTION_TYPE.BKASH
                        ? dispense.paid
                          ? " (Paid)"
                          : ""
                        : ""}
                    </Writing3>
                  </ListDiv>
                  <ListDiv>
                    <Writing3>{dispense.rfid}</Writing3>
                  </ListDiv>
                  <ListDiv>
                    <Writing3>{moment(dispense.dispensedAt).format("YYYY-MM-DD hh:mm A")}</Writing3>
                  </ListDiv>
                  <ListDiv>
                    <DispenseStatusText success={dispense.isSuccessful}>
                      {dispense.isSuccessful ? "Successful" : "Failed"}
                    </DispenseStatusText>
                  </ListDiv>
                  <ListDiv>
                    <Writing3>{dispense.failureReason}</Writing3>
                  </ListDiv>
                </ListItem>
              ))}
            </TBody>
            <Gap height="1.5rem" />
            <StyledPagination
              total={count}
              pageSize={pageSize}
              defaultCurrent={1}
              current={apiParams.page}
              onChange={handleChangePage}
              showLessItems={true}
              align={"center"}
            />
          </>
        ) : (
          <Loading size={"60px"} />
        )}
      </Container>
    </Container>
  );
};

export default RFID;
