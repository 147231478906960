import React from "react";
import { Pagination } from "antd";
import styled from "styled-components";

const PaginationStyled = styled(Pagination)<{ align?: string }>`
  text-align: ${({ align }) => align || "center"};
  li {
    font-size: 1rem;
    line-height: 38px;
    height: 38px;
    width: 38px;
    border-radius: 4px;
  }

  li,
  li .ant-pagination-item-link {
    background-color: transparent;
  }

  li.ant-pagination-item-active {
    background-color: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.bodyColor};
  }
`;

interface IProps {
  total: number;
  pageSize: number;
  defaultCurrent?: number;
  current?: number;
  onChange?: (pageNo: number) => void;
  showLessItems?: boolean;
  align?: "left" | "center" | "right";
}

const StyledPagination: React.FC<IProps> = ({
  total,
  pageSize,
  defaultCurrent = 1,
  current = 1,
  onChange,
  showLessItems,
  align,
}) => {
  const itemRender = (page: number, type: string, original: React.ReactNode) => {
    if (type === "page") return page;
    return original;
  };

  return (
    <PaginationStyled
      total={total}
      pageSize={pageSize}
      defaultCurrent={defaultCurrent}
      current={current}
      onChange={onChange}
      itemRender={itemRender}
      showLessItems={showLessItems}
      align={align}
      showSizeChanger={false}
    />
  );
};

export default StyledPagination;
