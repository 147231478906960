import { BASE_URL } from "src/utils/config";

export const getAllMachines = (institutionId: string) =>
  `${BASE_URL}/api/v1/machine/all/${institutionId}`;

export const createMachine = (institutionId: string) =>
  `${BASE_URL}/api/v1/machine/create/${institutionId}`;

export const updateMachine = (institutionId: string, machineId: string) =>
  `${BASE_URL}/api/v1/machine/update/${institutionId}/${machineId}`;

export const deleteMachine = (institutionId: string, machineId: string) =>
  `${BASE_URL}/api/v1/machine/remove/${institutionId}/${machineId}`;

export const getAllSizes = (institutionId: string, machineId: string) =>
  `${BASE_URL}/api/v1/machine/${institutionId}/${machineId}/size/all`;

export const getSize = (institutionId: string, machineId: string, sizeId: string) =>
  `${BASE_URL}/api/v1/machine/${institutionId}/${machineId}/size/${sizeId}`;

export const addSize = (institutionId: string, machineId: string) =>
  `${BASE_URL}/api/v1/machine/${institutionId}/${machineId}/size/add`;

export const updateSize = (institutionId: string, machineId: string, sizeId: string) =>
  `${BASE_URL}/api/v1/machine/${institutionId}/${machineId}/size/${sizeId}/update`;

export const deleteSize = (institutionId: string, machineId: string, sizeId: string) =>
  `${BASE_URL}/api/v1/machine/${institutionId}/${machineId}/size/${sizeId}/remove`;
