import { AxiosResponse } from "axios";
import {
  IDispenseGetAllResponse,
  IDispenseStatByMachineId,
  IGetAllQueryParams,
} from "../../types/dispense";
import { apiRequest } from "../apiRequest";
import { getAllDispenses, getSuccessfulDispenseStats } from "./dispenseApiUrl";

export function getAllDispensesApi(institutionId: string, query: IGetAllQueryParams) {
  return new Promise<AxiosResponse<IDispenseGetAllResponse>>((resolve, reject) => {
    apiRequest()
      .get(getAllDispenses(institutionId, query))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSuccessfulDispenseStatsApi(institutionId: string, start: string, end: string) {
  return new Promise<AxiosResponse<IDispenseStatByMachineId[]>>((resolve, reject) => {
    apiRequest()
      .get(getSuccessfulDispenseStats(institutionId, start, end))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
