import React, { createContext, useState, Context, useContext, useEffect } from "react";
import { ILoginInfo, loginAdminApi } from "src/api/admin/adminApi";
import { ILoginAdminApiError } from "src/api/admin/adminApiUrl";
import { removeAuthorizationApi, setAuthorizationApi } from "src/api/apiRequest";
import { handlePublicApiError } from "src/api/errorHandlers";
import { IAdmin, ITokens } from "src/types/admin";
import {
  getAdminAndTokenLocal,
  removeLocalAdminAndToken,
  setAdminAndTokensLocal,
} from "src/utils/localStorage";
import { showErrorToastAction, showToastAction } from "../toast";

interface IAdminAuth {
  admin: IAdmin;
  isAuthenticated: boolean;
  tokens: ITokens;
}

interface IAuthContextProps {
  adminAuth: IAdminAuth | undefined;
  loginAdminApiAction: (loginInfo: ILoginInfo, onSuccess?: () => void, onFail?: () => void) => void;
  logoutAdminApiAction: () => void;
}

export const AuthContext = createContext<IAuthContextProps | undefined>(
  undefined
) as Context<IAuthContextProps>;

const localAdminAndToken = getAdminAndTokenLocal();
if (localAdminAndToken) {
  setAuthorizationApi(localAdminAndToken.authAccessToken);
}

const AuthContextProvider: React.FC = (props: any) => {
  const [adminAuth, setAdminAuth] = useState<IAdminAuth | undefined>(
    localAdminAndToken
      ? {
          admin: localAdminAndToken.admin,
          isAuthenticated: true,
          tokens: {
            accessToken: localAdminAndToken.authAccessToken,
          },
        }
      : undefined
  );

  useEffect(() => {
    if (localAdminAndToken) {
      setAuthorizationApi(localAdminAndToken.authAccessToken);
      setAdminAuth({
        admin: localAdminAndToken.admin,
        isAuthenticated: true,
        tokens: {
          accessToken: localAdminAndToken.authAccessToken,
        },
      });
    }
  }, []);

  const loginAdminApiAction = (
    loginInfo: ILoginInfo,
    onSuccess?: () => void,
    onFail?: () => void
  ) => {
    loginAdminApi(loginInfo)
      .then((response) => {
        const data = response.data;
        const { admin, accessToken } = data;

        showToastAction({ type: "success", message: "Login Success" });
        setAuthorizationApi(accessToken);
        setAdminAndTokensLocal(admin, accessToken, accessToken);
        setAdminAuth({
          admin,
          isAuthenticated: true,
          tokens: {
            accessToken: accessToken,
          },
        });
        onSuccess && onSuccess();
      })
      .catch((err: ILoginAdminApiError) => {
        const { error, data } = handlePublicApiError(err);
        setAdminAuth(undefined);
        showErrorToastAction({
          message:
            data?.username ||
            data?.password ||
            data?.message ||
            data?.verified ||
            error ||
            "Login failed",
        });
        onFail && onFail();
      });
  };

  const logoutAdminApiAction = () => {
    removeLocalAdminAndToken();
    removeAuthorizationApi();
    showToastAction({ type: "success", message: "Logged out" });
    setAdminAuth(undefined);
  };

  const contextProps = {
    adminAuth,
    loginAdminApiAction,
    logoutAdminApiAction,
  };

  return <AuthContext.Provider value={contextProps}>{props.children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAdminAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAdminAuthContext must be used within a AuthContextProvider");
  }
  return context;
};
