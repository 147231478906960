import styled from "styled-components";

const Heading1 = styled.h1`
  font-size: 1.5em;
  font-weight: 600;
  color: ${({ color, theme }) => (color ? color : theme.colors.secondaryColor)};
  margin-bottom: 0em;
`;

const Heading2 = styled.h1`
  font-size: 1.2em;
  font-weight: 600;
  color: ${({ color, theme }) => (color ? color : theme.colors.secondaryColor)};
  margin-bottom: 0em;
`;

const Heading3 = styled.h1`
  font-size: 1em;
  font-weight: 500;
  color: ${({ color, theme }) => (color ? color : theme.colors.secondaryColor)};
  margin-bottom: 0em;
`;

const Heading4 = styled.h1`
  font-size: 0.875em;
  font-weight: 500;
  color: ${({ color, theme }) => (color ? color : theme.colors.secondaryColor)};
  margin-bottom: 0em;
`;

const Heading5 = styled.h1`
  font-size: 0.875em;
  font-weight: 400;
  color: ${({ color, theme }) => (color ? color : theme.colors.secondaryColor)};
  margin-bottom: 0em;
`;

const Heading6 = styled.h1`
  font-size: 0.75em;
  font-weight: 400;
  color: ${({ color, theme }) => (color ? color : theme.colors.secondaryColor)};
  margin-bottom: 0em;
`;

export { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 };
