import { Space, Switch } from "antd";
import React, { useState } from "react";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading, Status } from "src/components/atoms/displays";
import { Gap } from "src/components/atoms/spaces";
import { Writing1, Writing2, Writing3 } from "src/components/atoms/texts/writing";
import { TextFieldForm } from "src/components/molecules/inputfields";
import { useRFIDContext } from "src/contexts/rfid/RFIDContext";
import { IRFID, IRFIDCreate, IRFIDUpdate } from "src/types/rfid";
import { firstLetterToUpperCase } from "src/utils/strings";
import { validateRFIDCreate, validateRFIDUpdate } from "src/validators/rfidValidator";
// import { SelectForm } from "src/components/molecules/selects";
import { Container } from "src/components/atoms/grid";
import {
  THead,
  TBody,
  THeader,
  ListDiv,
  ListItem,
  EditIcon,
  DeleteIcon,
  FlexContainer,
  StyledModal,
  StyledDeleteModal,
  StopIcon,
  CheckIcon,
} from "./common";
// import { useInstitutionsContext } from "../../../contexts/institution/InstitutionsContext";
import styled from "styled-components";
import { IconTooltip } from "../../molecules/texts/iconTooltip";

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;
interface IProps {
  institutionId: string;
}

const initialInputs: IRFIDCreate = {
  name: undefined,
  designation: undefined,
  designationId: undefined,
  physicalId: "",
  stockType: "LIMITED",
  point: 0,
  isFree: false,
};

const RFID: React.FC<IProps> = ({ institutionId }) => {
  const {
    rfidLoading,
    rfidChangeLoading,
    rfids,
    createRFIDApiAction,
    updateRFIDApiAction,
    deleteRFIDApiAction,
  } = useRFIDContext();
  // const { currentInstitution } = useInstitutionsContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IRFID;
    type: "create" | "update" | "delete" | "block" | "activate" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<IRFIDCreate | IRFIDUpdate>(initialInputs);

  const [error, setError] = useState<any>({});

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData(initialInputs);
    setError({});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeNumberInput = (n: keyof (IRFIDCreate | IRFIDUpdate), val?: string) => {
    const numberValue = !val ? undefined : Number(val) < 0 ? 0 : Number(val);
    setInputData((prev) => ({ ...prev, [n]: numberValue }));
  };

  // const handleChangeSelect = (value: TStockType) => {
  //   setInputData((prev) => ({ ...prev, stockType: value }));
  // };

  const handleCreateModal = () => {
    setModal({ visible: true, type: "create" });
    setInputData(initialInputs);
  };

  const onCreateRFID = async () => {
    const { errors, isValid } = await validateRFIDCreate(inputData);
    if (isValid && institutionId) {
      setError({});
      await createRFIDApiAction(institutionId, inputData as IRFIDCreate);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleUpdateModal = (rfid: IRFID) => {
    setModal({ visible: true, data: rfid, type: "update" });
    setInputData({
      name: rfid.name,
      designation: rfid.designation,
      designationId: rfid.designationId,
      physicalId: rfid.physicalId,
      stockType: rfid.stockType,
      isActive: rfid.isActive,
      point: rfid.point ?? 0,
      isFree: !!rfid.isFree,
    });
  };

  const onUpdateRFID = async () => {
    const { errors, isValid } = await validateRFIDUpdate(inputData);
    if (isValid && institutionId && (inputData as IRFIDCreate).physicalId && modal.data?._id) {
      setError({});
      await updateRFIDApiAction(institutionId, modal.data._id, inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleDeleteModal = (data: IRFID) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteRFID = async () => {
    if (modal.data?._id) {
      await deleteRFIDApiAction(institutionId, modal.data._id);
      handleModalClose();
    }
  };

  const handleBlockModal = (rfid: IRFID, type: "block" | "activate") => {
    setModal({ visible: true, data: rfid, type: type });
    setInputData({
      name: rfid.name,
      designation: rfid.designation,
      designationId: rfid.designationId,
      physicalId: rfid.physicalId,
      stockType: rfid.stockType,
      point: rfid.point,
      isActive: type === "activate",
      isFree: !!rfid.isFree,
    });
  };

  const handleToggle = (n: keyof IRFIDCreate, bool: boolean) => {
    setInputData((prev) => ({ ...prev, [n]: bool }));
  };

  return (
    <Container>
      <StyledModal
        title={firstLetterToUpperCase(modal.type) + " RFID"}
        visible={modal.visible && (modal.type === "create" || modal.type === "update")}
        confirmLoading={rfidChangeLoading}
        onOk={
          modal.type === "create"
            ? () => onCreateRFID()
            : modal.type === "update"
            ? () => onUpdateRFID()
            : () => null
        }
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          <TextFieldForm
            labelSize={1}
            labelText="RFID"
            placeholder="rfid"
            name="physicalId"
            value={(inputData as any).physicalId}
            onChange={handleChangeName}
            disabled={modal.type === "update"}
            errorMessage={error["physicalId"]}
          />
          <Gap height={"0.75rem"} />

          <TextFieldForm
            labelSize={1}
            labelText="Name"
            placeholder="name"
            name="name"
            value={inputData.name}
            onChange={handleChangeName}
            errorMessage={error["name"]}
          />
          <Gap height={"0.75rem"} />

          <ToggleContainer>
            <FlexContainer>
              <Writing1>Is RFID free?</Writing1>
              <Gap width="0.5rem" />
              <IconTooltip tooltip="Free RFID can be used to dispense in any point enabled machine." />
            </FlexContainer>
            <Switch checked={inputData.isFree} onChange={(bool) => handleToggle("isFree", bool)} />
          </ToggleContainer>
          <TextFieldForm
            type="number"
            labelSize={1}
            labelText="Points"
            placeholder="point"
            name="point"
            disabled={inputData.isFree}
            value={inputData.point ? parseInt(inputData.point.toString()) : undefined}
            onChange={(e) => handleChangeNumberInput("point", e.target.value)}
            errorMessage={error["point"]}
          />
          <Gap height={"0.75rem"} />

          {/* <TextFieldForm
            labelSize={1}
            labelText="Designation"
            placeholder="designation"
            name="designation"
            value={inputData.designation}
            onChange={handleChangeName}
            errorMessage={error["designation"]}
          />
          <Gap height={"0.75rem"} />

          <TextFieldForm
            labelSize={1}
            labelText="Designation ID"
            placeholder="designationId"
            name="designationId"
            value={inputData.designationId}
            onChange={handleChangeName}
            errorMessage={error["designationId"]}
          />
          <Gap height={"0.75rem"} /> */}

          {/* <SelectForm
            labelSize={1}
            labelText="Stock Type"
            name="stockType"
            placeholder="Stock Type"
            onChange={(_n, _k, v) => handleChangeSelect(v as TStockType)}
            options={stockType}
            value={stockType.indexOf(inputData.stockType)}
          /> */}
        </>
      </StyledModal>
      <StyledDeleteModal
        visible={
          (modal.visible && modal.type === "delete") ||
          modal.type === "block" ||
          modal.type === "activate"
        }
        title={firstLetterToUpperCase(modal.type) + " RFID"}
        onOk={
          modal.type === "delete"
            ? () => onDeleteRFID()
            : modal.type === "activate" || modal.type === "block"
            ? () => onUpdateRFID()
            : () => null
        }
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={rfidChangeLoading}
      >
        <Writing2>
          Are you sure you want to {modal.type} this rfid "{modal.data?.physicalId}"?
        </Writing2>
      </StyledDeleteModal>

      <FlexContainer>
        <SolidButton onClick={() => handleCreateModal()}>Add RFID</SolidButton>
      </FlexContainer>

      <Gap height={"1.5rem"} />

      {!rfidLoading ? (
        <Container>
          <THeader columns={4}>
            <THead>
              <Writing3>RFID</Writing3>
            </THead>
            <THead>
              <Writing3>Name</Writing3>
            </THead>
            {/* <THead>
              <Writing3>Stock Type</Writing3>
            </THead> */}
            <THead>
              <Writing3>Point</Writing3>
            </THead>
            <THead>
              <Writing3>Actions</Writing3>
            </THead>
          </THeader>

          <TBody>
            {rfids.map((rfid) => (
              <ListItem key={rfid._id} columns={4}>
                <ListDiv>
                  <Status
                    label={rfid.physicalId}
                    title={rfid.isActive ? "Active" : "Blocked"}
                    status={rfid.isActive ? "running" : "expired"}
                  />
                </ListDiv>
                <ListDiv>
                  <Writing3>{rfid.name}</Writing3>
                </ListDiv>
                {/* <ListDiv>
                  <Writing3>{rfid.stockType}</Writing3>
                </ListDiv> */}
                <ListDiv>
                  <Writing3>{rfid.isFree ? "Free" : rfid.point ?? "-"}</Writing3>
                </ListDiv>
                <ListDiv>
                  <Space size={"large"} align={"center"}>
                    <EditIcon title="Edit rfid" onClick={() => handleUpdateModal(rfid)} />
                    <DeleteIcon title="Delete rfid" onClick={() => handleDeleteModal(rfid)} />
                    {rfid.isActive ? (
                      <StopIcon
                        title="Block rfid"
                        onClick={() => handleBlockModal(rfid, "block")}
                      />
                    ) : (
                      <CheckIcon
                        title="Activate rfid"
                        onClick={() => handleBlockModal(rfid, "activate")}
                      />
                    )}
                  </Space>
                </ListDiv>
              </ListItem>
            ))}
          </TBody>
        </Container>
      ) : (
        <Loading size={"60px"} />
      )}
    </Container>
  );
};

export default RFID;
