import { Modal } from "antd";
import React, { useState } from "react";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading } from "src/components/atoms/displays";
import { GridContainer } from "src/components/atoms/grid";
import { Gap } from "src/components/atoms/spaces";
import { Heading2 } from "src/components/atoms/texts/heading";
import { Writing2 } from "src/components/atoms/texts/writing";
import { NameCard } from "src/components/molecules/cards";
import { PasswordFieldForm, TextFieldForm } from "src/components/molecules/inputfields";
import { useInstitutionsContext } from "src/contexts/institution/InstitutionsContext";
import { useOrganizationsContext } from "src/contexts/organization/OrganizationsContext";
import { ICreateAdmin, IInstitutionAdmin, IOrganizationAdmin } from "src/types/admin";
import { firstLetterToUpperCase } from "src/utils/strings";
import { validateAdminCreate } from "src/validators/adminValidator";
import styled from "styled-components";

const Container = styled.div``;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0.75rem;
  }
`;

const StyledDeleteModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0.75rem;
    }
  }
`;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  admins: IOrganizationAdmin[] | IInstitutionAdmin[];
  organizationId?: string;
  institutionId?: string;
  type: "ORGANIZATION" | "INSTITUTION";
}

const Admins: React.FC<IProps> = ({ admins, organizationId, institutionId, type }) => {
  const {
    organizationAdminsLoading,
    organizationAdminsChangeLoading,
    createOrganizationAdminApiAction,
    deleteOrganizationAdminApiAction,
  } = useOrganizationsContext();
  const {
    institutionAdminsLoading,
    institutionAdminsChangeLoading,
    createInstitutionAdminApiAction,
    deleteInstitutionAdminApiAction,
  } = useInstitutionsContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IOrganizationAdmin;
    type: "create" | "delete" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<ICreateAdmin>({ username: "", password: "" });

  const [error, setError] = useState<any>({});

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData({ username: "", password: "" });
    setError({});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCreateModal = () => {
    setModal({ visible: true, type: "create" });
  };

  const onCreateOrganizationAdmin = async () => {
    const { errors, isValid } = await validateAdminCreate(inputData);
    if (isValid) {
      setError({});
      if (type === "ORGANIZATION" && organizationId) {
        await createOrganizationAdminApiAction(organizationId, inputData);
        handleModalClose();
      } else if (type === "INSTITUTION" && institutionId) {
        await createInstitutionAdminApiAction(institutionId, inputData);
        handleModalClose();
      }
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleDeleteModal = (data: IOrganizationAdmin) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteOrganizationAdmin = async () => {
    if (modal.data?._id) {
      if (type === "ORGANIZATION" && organizationId) {
        await deleteOrganizationAdminApiAction(organizationId, modal.data._id);
        handleModalClose();
      } else if (type === "INSTITUTION" && institutionId) {
        await deleteInstitutionAdminApiAction(institutionId, modal.data._id);
        handleModalClose();
      }
    }
  };

  return (
    <Container>
      <StyledModal
        title={firstLetterToUpperCase(modal.type) + " Admin"}
        visible={modal.visible && modal.type !== "delete"}
        confirmLoading={institutionAdminsChangeLoading || organizationAdminsChangeLoading}
        onOk={modal.type === "create" ? () => onCreateOrganizationAdmin() : () => null}
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          <TextFieldForm
            labelSize={1}
            labelText="Username"
            placeholder="username"
            name="username"
            value={inputData.username}
            onChange={handleChangeName}
            errorMessage={error["username"]}
          />
          <Gap height={"1rem"} />
          <PasswordFieldForm
            labelSize={1}
            labelText="Password"
            placeholder="password"
            name="password"
            value={inputData.password}
            onChange={handleChangeName}
            errorMessage={error["password"]}
          />
        </>
      </StyledModal>

      <StyledDeleteModal
        visible={modal.visible && modal.type === "delete"}
        title={firstLetterToUpperCase(modal.type) + " Admin"}
        onOk={() => onDeleteOrganizationAdmin()}
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={institutionAdminsChangeLoading || organizationAdminsChangeLoading}
      >
        <Writing2>Are you sure you want to delete this admin "{modal.data?.username}"?</Writing2>
      </StyledDeleteModal>

      <FlexContainer>
        {admins ? <SolidButton onClick={handleCreateModal}>Add Admin</SolidButton> : null}
      </FlexContainer>

      <Gap height={"1.5rem"} />

      {institutionAdminsLoading || organizationAdminsLoading ? (
        <Loading size="60px" />
      ) : admins.length ? (
        <GridContainer>
          {(admins as any).map((admin: any) => (
            <NameCard
              key={admin._id}
              name={admin.username}
              onDelete={() => handleDeleteModal(admin)}
            />
          ))}
        </GridContainer>
      ) : (
        <NotFound>
          <Heading2>No admin found</Heading2>
        </NotFound>
      )}
    </Container>
  );
};

export default Admins;
