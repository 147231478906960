import React from "react";
import { ColumnContainer } from "../atoms/grid";
import styled from "styled-components";
import LandingMain from "src/components/organisms/landing";


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(ColumnContainer)`
  display: flex;
  align-items: center;
`;

const Landing: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <LandingMain />
      </Container>
    </Wrapper>
  );
};

export { Landing };
