import { Space } from "antd";
import React, { useState } from "react";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading } from "src/components/atoms/displays";
import { Gap } from "src/components/atoms/spaces";
import { Writing2, Writing3 } from "src/components/atoms/texts/writing";
import { TextFieldForm } from "src/components/molecules/inputfields";
import { firstLetterToUpperCase } from "src/utils/strings";
import { Container } from "src/components/atoms/grid";
import {
  THead,
  TBody,
  THeader,
  ListDiv,
  ListItem,
  EditIcon,
  DeleteIcon,
  FlexContainer,
  StyledModal,
  StyledDeleteModal,
  StopIcon,
  CheckIcon,
} from "../institutionAdminDashboard/common";
import { useOrganizationsContext } from "../../../contexts/organization/OrganizationsContext";
import { IUnlockRFID } from "../../../types/organization";
import { validateUnlockRFID } from "../../../validators/organizationValidator";

interface IProps {
  organizationId: string;
  rfids?: IUnlockRFID[];
}

const initialInputs: IUnlockRFID = {
  name: undefined,
  physicalId: "",
  isActive: true,
};

const UnlockRFID: React.FC<IProps> = ({ organizationId, rfids }) => {
  const {
    unlockRFIDActionLoading,
    addUnlockRFIDApiAction,
    updateUnlockRFIDApiAction,
    deleteUnlockRFIDApiAction,
  } = useOrganizationsContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IUnlockRFID;
    type: "add" | "update" | "delete" | "block" | "activate" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<IUnlockRFID>(initialInputs);

  const [error, setError] = useState<any>({});

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData(initialInputs);
    setError({});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddModal = () => {
    setModal({ visible: true, type: "add" });
    setInputData(initialInputs);
  };

  const onAddRFID = async () => {
    const { errors, isValid } = await validateUnlockRFID(inputData);
    if (isValid && organizationId) {
      setError({});
      await addUnlockRFIDApiAction(organizationId, inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleUpdateModal = (rfid: IUnlockRFID) => {
    setModal({ visible: true, data: rfid, type: "update" });
    setInputData({
      name: rfid.name,
      physicalId: rfid.physicalId,
      isActive: rfid.isActive,
    });
  };

  const onUpdateRFID = async () => {
    const { errors, isValid } = await validateUnlockRFID(inputData);
    if (isValid && organizationId && inputData.physicalId && modal.data?._id) {
      setError({});
      await updateUnlockRFIDApiAction(organizationId, modal.data._id, inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleDeleteModal = (data: IUnlockRFID) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteRFID = async () => {
    if (modal.data?._id) {
      await deleteUnlockRFIDApiAction(organizationId, modal.data._id);
      handleModalClose();
    }
  };

  const handleBlockModal = (rfid: IUnlockRFID, type: "block" | "activate") => {
    setModal({ visible: true, data: rfid, type: type });
    setInputData({
      name: rfid.name,
      physicalId: rfid.physicalId,
      isActive: type === "activate",
    });
  };

  return (
    <Container>
      <StyledModal
        title={firstLetterToUpperCase(modal.type) + " RFID"}
        visible={(modal.visible && modal.type === "add") || modal.type === "update"}
        confirmLoading={unlockRFIDActionLoading}
        onOk={
          modal.type === "add"
            ? () => onAddRFID()
            : modal.type === "update"
            ? () => onUpdateRFID()
            : () => null
        }
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          <TextFieldForm
            labelSize={1}
            labelText="RFID"
            placeholder="rfid"
            name="physicalId"
            value={(inputData as any).physicalId}
            onChange={handleChangeName}
            errorMessage={error["physicalId"]}
          />
          <Gap height={"0.75rem"} />

          <TextFieldForm
            labelSize={1}
            labelText="Name"
            placeholder="name"
            name="name"
            value={inputData.name}
            onChange={handleChangeName}
            errorMessage={error["name"]}
          />
        </>
      </StyledModal>
      <StyledDeleteModal
        visible={
          (modal.visible && modal.type === "delete") ||
          modal.type === "block" ||
          modal.type === "activate"
        }
        title={firstLetterToUpperCase(modal.type) + " RFID"}
        onOk={
          modal.type === "delete"
            ? () => onDeleteRFID()
            : modal.type === "activate" || modal.type === "block"
            ? () => onUpdateRFID()
            : () => null
        }
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={unlockRFIDActionLoading}
      >
        <Writing2>
          Are you sure you want to {modal.type} this rfid "{modal.data?.physicalId}"?
        </Writing2>
      </StyledDeleteModal>

      <FlexContainer>
        <SolidButton onClick={() => handleAddModal()}>Add RFID</SolidButton>
      </FlexContainer>

      <Gap height={"1.5rem"} />

      {!unlockRFIDActionLoading ? (
        <Container>
          <THeader columns={4}>
            <THead>
              <Writing3>RFID</Writing3>
            </THead>
            <THead>
              <Writing3>Name</Writing3>
            </THead>
            <THead>
              <Writing3>Actions</Writing3>
            </THead>
          </THeader>

          <TBody>
            {rfids?.map((rfid) => (
              <ListItem key={rfid?._id} columns={4}>
                <ListDiv>{rfid.physicalId}</ListDiv>
                <ListDiv>
                  <Writing3>{rfid.name}</Writing3>
                </ListDiv>
                <ListDiv>
                  <Space size={"large"} align={"center"}>
                    <EditIcon title="Edit rfid" onClick={() => handleUpdateModal(rfid)} />
                    <DeleteIcon title="Delete rfid" onClick={() => handleDeleteModal(rfid)} />
                    {rfid.isActive ? (
                      <StopIcon
                        title="Block rfid"
                        onClick={() => handleBlockModal(rfid, "block")}
                      />
                    ) : (
                      <CheckIcon
                        title="Activate rfid"
                        onClick={() => handleBlockModal(rfid, "activate")}
                      />
                    )}
                  </Space>
                </ListDiv>
              </ListItem>
            ))}
          </TBody>
        </Container>
      ) : (
        <Loading size={"60px"} />
      )}
    </Container>
  );
};

export default UnlockRFID;
