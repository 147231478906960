import { AxiosResponse } from "axios";
import { IMachine, IMachineCreateUpdate, IProductSize } from "src/types/machine";
import { apiRequest } from "../apiRequest";
import {
  addSize,
  createMachine,
  deleteMachine,
  deleteSize,
  getAllMachines,
  getAllSizes,
  updateMachine,
  updateSize,
} from "./machineApiUrl";

export function getAllMachinesApi(institutionId: string) {
  return new Promise<AxiosResponse<IMachine[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllMachines(institutionId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createMachineApi(institutionId: string, createData: IMachineCreateUpdate) {
  return new Promise<AxiosResponse<IMachine>>((resolve, reject) => {
    apiRequest()
      .post(createMachine(institutionId), createData)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateMachineApi(
  institutionId: string,
  machineId: string,
  updateData: IMachineCreateUpdate
) {
  return new Promise<AxiosResponse<IMachine>>((resolve, reject) => {
    apiRequest()
      .put(updateMachine(institutionId, machineId), updateData)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

interface IDeleteResponse {
  message: string;
}

export function deleteMachineApi(institutionId: string, machineId: string) {
  return new Promise<AxiosResponse<IDeleteResponse>>((resolve, reject) => {
    apiRequest()
      .delete(deleteMachine(institutionId, machineId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllSizesApi(institutionId: string, machineId: string) {
  return new Promise<AxiosResponse<IProductSize[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllSizes(institutionId, machineId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addSizeApi(institutionId: string, machineId: string, data: IProductSize) {
  return new Promise<AxiosResponse<IMachine>>((resolve, reject) => {
    apiRequest()
      .patch(addSize(institutionId, machineId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSizeApi(
  institutionId: string,
  machineId: string,
  sizeId: string,
  data: IProductSize
) {
  return new Promise<AxiosResponse<IMachine>>((resolve, reject) => {
    apiRequest()
      .patch(updateSize(institutionId, machineId, sizeId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteSizeApi(institutionId: string, machineId: string, sizeId: string) {
  return new Promise<AxiosResponse<IMachine>>((resolve, reject) => {
    apiRequest()
      .delete(deleteSize(institutionId, machineId, sizeId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
