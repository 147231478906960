import { AxiosResponse } from "axios";
import { IRFID, IRFIDCreate, IRFIDUpdate } from "src/types/rfid";
import { apiRequest } from "../apiRequest";
import { createRFID, deleteRFID, getAllRFID, getRFIDById, updateRFID } from "./rfidApiUrl";

export function getAllRFIDApi(institutionId: string) {
  return new Promise<AxiosResponse<IRFID[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllRFID(institutionId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getRFIDByIdApi(institutionId: string, rfidId: string) {
  return new Promise<AxiosResponse<IRFID>>((resolve, reject) => {
    apiRequest()
      .get(getRFIDById(institutionId, rfidId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createRFIDApi(institutionId: string, createData: IRFIDCreate) {
  return new Promise<AxiosResponse<IRFID>>((resolve, reject) => {
    apiRequest()
      .post(createRFID(institutionId), createData)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateRFIDApi(institutionId: string, rfidId: string, updateData: IRFIDUpdate) {
  return new Promise<AxiosResponse<IRFID>>((resolve, reject) => {
    apiRequest()
      .put(updateRFID(institutionId, rfidId), updateData)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

interface IDeleteResponse {
  message: string;
}

export function deleteRFIDApi(institutionId: string, rfidId: string) {
  return new Promise<AxiosResponse<IDeleteResponse>>((resolve, reject) => {
    apiRequest()
      .delete(deleteRFID(institutionId, rfidId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
