import React from "react";
import styled from "styled-components";

const Container = styled.div<IProps>`
  box-shadow: ${({ theme }) => "1px 3px 30px " + theme.colors.shadowColor};
  border-radius: ${({ rounded }) => (rounded ? "5px" : "")};
`;
interface IProps {
  rounded?: boolean;
  className?: string;
}

const ShadowContainer: React.FC<IProps> = (props) => {
  return (
    <Container className={props.className} rounded={props.rounded}>
      {props.children}
    </Container>
  );
};

export default ShadowContainer;
