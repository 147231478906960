import React, { Context, createContext, useCallback, useContext, useState } from "react";
import {
  createOrganizationAdminApi,
  deleteOrganizationAdminApi,
  getAllOrganizationAdminsApi,
} from "src/api/admin/adminApi";
import { ICommonApiError } from "src/api/apiRequest";
import { handlePrivateApiError } from "src/api/errorHandlers";
import {
  addUnlockRFIDApi,
  createOrganizationApi,
  deleteOrganizationApi,
  deleteUnlockRFIDApi,
  getAllOrganizationsApi,
  getOrganizationByIdApi,
  updateOrganizationApi,
  updateUnlockRFIDApi
} from "src/api/organization/organizationApi";
import { ICreateAdmin, IOrganizationAdmin } from "src/types/admin";
import {
  ICreateUpdateOrganization,
  IOrganization,
  IUnlockRFID
} from "src/types/organization";
import { FETCH_INTERVAL_MINUTES } from "src/utils/config";
import { getTimeDiff } from "src/utils/dateTimeHelpers";
import { useAdminAuthContext } from "../admin/AuthContext";
import { showErrorToastAction } from "../toast";

interface IOrganizationsContextProps {
  organizationsLoading: boolean;
  organizationsChangeLoading: boolean;
  organizationAdminsLoading: boolean;
  organizationAdminsChangeLoading: boolean;
  unlockRFIDActionLoading: boolean;
  currentOrganization: IOrganization | undefined;
  organizations: IOrganization[];
  organizationAdmins: IOrganizationAdmin[];
  getAllOrganizationsApiAction: () => void;
  getOrganizationByIdApiAction: (organizationId: string) => Promise<void>;
  createOrganizationApiAction: (createData: ICreateUpdateOrganization) => Promise<void>;
  updateOrganizationApiAction: (
    organizationId: string,
    editData: ICreateUpdateOrganization
  ) => Promise<void>;
  deleteOrganizationApiAction: (organizationId: string) => Promise<void>;
  getAllOrganizationAdminApiAction: (organizationId: string) => void;
  createOrganizationAdminApiAction: (
    organizationId: string | undefined,
    createData: ICreateAdmin
  ) => Promise<void>;
  deleteOrganizationAdminApiAction: (organizationId: string, adminId: string) => Promise<void>;
  addUnlockRFIDApiAction: (organizationId: string, data: IUnlockRFID) => Promise<void>;
  updateUnlockRFIDApiAction: (
    organizationId: string,
    rfidId: string,
    data: IUnlockRFID
  ) => Promise<void>;
  deleteUnlockRFIDApiAction: (organizationId: string, rfidId: string) => Promise<void>;
}

export const OrganizationsContext = createContext<IOrganizationsContextProps | undefined>(
  undefined
) as Context<IOrganizationsContextProps>;

const OrganizationsProvider: React.FC = (props: any) => {
  const [organizationsLoading, setOrganizationsLoading] = useState<boolean>(false);
  const [organizationsChangeLoading, setOrganizationsChangeLoading] = useState<boolean>(false);
  const [organizationAdminsLoading, setOrganizationAdminsLoading] = useState<boolean>(false);
  const [organizationAdminsChangeLoading, setOrganizationAdminsChangeLoading] =
    useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<IOrganization | undefined>(
    undefined
  );
  const [organizationAdmins, setOrganizationAdmins] = useState<IOrganizationAdmin[]>([]);

  const [lastFetchOrganizations, setLastFetchOrganizations] = useState<Date>();

  const [unlockRFIDActionLoading, setUnlockRFIDActionLoading] = useState(false);

  // Log out action from Auth context to use in privateErrors
  const { logoutAdminApiAction } = useAdminAuthContext();

  /**
   * Organization Api Actions
   * Get all
   * Get By ID
   * Crete
   * Update
   * Delete
   */
  const getAllOrganizationsApiAction = useCallback(() => {
    let diff;
    if (lastFetchOrganizations) diff = getTimeDiff(lastFetchOrganizations, "minutes");
    if (diff === undefined || diff > FETCH_INTERVAL_MINUTES) {
      setOrganizationsLoading(true);
      getAllOrganizationsApi()
        .then((res) => {
          setOrganizations(res.data);
          setLastFetchOrganizations(new Date());
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to fetch organizations",
          });
        })
        .finally(() => {
          setOrganizationsLoading(false);
        });
    }
  }, [logoutAdminApiAction, lastFetchOrganizations]);

  const getOrganizationByIdApiAction = useCallback(
    async (organizationId: string) => {
      if (currentOrganization?._id === organizationId) {
        return;
      }
      setCurrentOrganization(undefined);

      try {
        const res = await getOrganizationByIdApi(organizationId);
        setCurrentOrganization(res.data);
      } catch (err) {
        const { error, data } = handlePrivateApiError(err as ICommonApiError, logoutAdminApiAction);
        showErrorToastAction({
          message: data?.message || error || "Failed to fetch organization",
        });
        setCurrentOrganization(undefined);
      }
    },
    [logoutAdminApiAction, currentOrganization]
  );

  const createOrganizationApiAction = (createData: ICreateUpdateOrganization) => {
    return new Promise<void>((resolve) => {
      setOrganizationsChangeLoading(true);
      createOrganizationApi(createData)
        .then((res) => {
          setOrganizations((prev) => [...prev, res.data]);
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to create organization",
          });
        })
        .finally(() => {
          setOrganizationsChangeLoading(false);
          resolve();
        });
    });
  };

  const updateOrganizationApiAction = (
    organizationId: string,
    editData: ICreateUpdateOrganization
  ) => {
    return new Promise<void>((resolve) => {
      setOrganizationsChangeLoading(true);
      updateOrganizationApi(organizationId, editData)
        .then((res) => {
          if (currentOrganization?._id === organizationId) setCurrentOrganization(res.data);
          setOrganizations((prev) => {
            const index = prev.findIndex((organization) => organization._id === organizationId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to update organization",
          });
        })
        .finally(() => {
          setOrganizationsChangeLoading(false);
          resolve();
        });
    });
  };

  const deleteOrganizationApiAction = (organizationId: string) => {
    return new Promise<void>((resolve) => {
      setOrganizationsChangeLoading(true);
      deleteOrganizationApi(organizationId)
        .then(() => {
          if (currentOrganization?._id === organizationId) setCurrentOrganization(undefined);
          setOrganizations((prev) => {
            const filteredData = prev.filter((organization) => organization._id !== organizationId);
            return [...filteredData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to delete organization",
          });
        })
        .finally(() => {
          setOrganizationsChangeLoading(false);
          resolve();
        });
    });
  };

  /**
   * Admins Api Actions
   * Get all
   * Crete
   * Delete
   */
  const getAllOrganizationAdminApiAction = useCallback(
    (organizationId: string) => {
      if (currentOrganization?._id !== organizationId) {
        setOrganizationAdminsLoading(true);
        getAllOrganizationAdminsApi(organizationId)
          .then((res) => {
            setOrganizationAdmins(res.data);
          })
          .catch((err: ICommonApiError) => {
            const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
            showErrorToastAction({
              message: data?.message || error || "Failed to fetch admins",
            });
          })
          .finally(() => {
            setOrganizationAdminsLoading(false);
          });
      }
    },
    [logoutAdminApiAction, currentOrganization]
  );

  const createOrganizationAdminApiAction = (
    organizationId: string | undefined,
    createData: ICreateAdmin
  ) => {
    return new Promise<void>((resolve) => {
      setOrganizationAdminsChangeLoading(true);
      createOrganizationAdminApi(organizationId as string, createData)
        .then((res) => {
          setOrganizationAdmins((prev) => [...prev, res.data]);
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to create admin",
          });
        })
        .finally(() => {
          setOrganizationAdminsChangeLoading(false);
          resolve();
        });
    });
  };

  const deleteOrganizationAdminApiAction = (organizationId: string, adminId: string) => {
    return new Promise<void>((resolve) => {
      setOrganizationAdminsChangeLoading(true);
      deleteOrganizationAdminApi(organizationId, adminId)
        .then(() => {
          setOrganizationAdmins((prev) => {
            const filteredData = prev.filter((admin) => admin._id !== adminId);
            return [...filteredData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to delete admin",
          });
        })
        .finally(() => {
          setOrganizationAdminsChangeLoading(false);
          resolve();
        });
    });
  };

  const addUnlockRFIDApiAction = (organizationId: string, data: IUnlockRFID) => {
    return new Promise<void>((resolve) => {
      setUnlockRFIDActionLoading(true);
      addUnlockRFIDApi(organizationId, data)
        .then((res) => {
          if (currentOrganization?._id === organizationId) setCurrentOrganization(res.data);
          setOrganizations((prev) => {
            const index = prev.findIndex((organization) => organization._id === organizationId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to add unlock RFID",
          });
        })
        .finally(() => {
          setUnlockRFIDActionLoading(false);
          resolve();
        });
    });
  };

  const updateUnlockRFIDApiAction = (organizationId: string, rfidId: string, data: IUnlockRFID) => {
    return new Promise<void>((resolve) => {
      setUnlockRFIDActionLoading(true);
      updateUnlockRFIDApi(organizationId, rfidId, data)
        .then((res) => {
          if (currentOrganization?._id === organizationId) setCurrentOrganization(res.data);
          setOrganizations((prev) => {
            const index = prev.findIndex((organization) => organization._id === organizationId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to update unlock RFID",
          });
        })
        .finally(() => {
          setUnlockRFIDActionLoading(false);
          resolve();
        });
    });
  };

  const deleteUnlockRFIDApiAction = (organizationId: string, rfidId: string) => {
    return new Promise<void>((resolve) => {
      setUnlockRFIDActionLoading(true);
      deleteUnlockRFIDApi(organizationId, rfidId)
        .then((res) => {
          if (currentOrganization?._id === organizationId) setCurrentOrganization(res.data);
          setOrganizations((prev) => {
            const index = prev.findIndex((organization) => organization._id === organizationId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to remove unlock RFID",
          });
        })
        .finally(() => {
          setUnlockRFIDActionLoading(false);
          resolve();
        });
    });
  };

  const contextProps = {
    organizationsLoading,
    organizationsChangeLoading,
    organizationAdminsLoading,
    organizationAdminsChangeLoading,
    unlockRFIDActionLoading,
    currentOrganization,
    organizations,
    organizationAdmins,
    getAllOrganizationsApiAction,
    getOrganizationByIdApiAction,
    createOrganizationApiAction,
    updateOrganizationApiAction,
    deleteOrganizationApiAction,
    getAllOrganizationAdminApiAction,
    createOrganizationAdminApiAction,
    deleteOrganizationAdminApiAction,
    addUnlockRFIDApiAction,
    updateUnlockRFIDApiAction,
    deleteUnlockRFIDApiAction,
  };

  return (
    <OrganizationsContext.Provider value={contextProps}>
      {props.children}
    </OrganizationsContext.Provider>
  );
};

export default OrganizationsProvider;

export const useOrganizationsContext = () => {
  const context = useContext(OrganizationsContext);
  if (!context) {
    throw new Error("useOrganizationContext must be used within a OrganizationsProvider");
  }
  return context;
};
