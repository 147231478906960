import React from "react";
import { Button } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import styled from "styled-components";

const ButtonStyled = styled(Button)`
  border-radius: 5px;
`;

interface IProps {
  type?: "button" | "submit" | "reset";
  size?: SizeType;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const BorderedButton: React.FC<IProps> = (props) => {
  const {
    type,
    size,
    onClick,
    loading,
    loadingText,
    disabled,
    className,
  } = props;
  return (
    <ButtonStyled
      htmlType={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
      size={size || "large"}
      loading={loading}
    >
      {loading ? loadingText || props.children : props.children}
    </ButtonStyled>
  );
};

export default BorderedButton;
