import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";

const MyCheckbox = styled<React.ComponentType<IMyProps>>(Checkbox)`
  transform: ${({ size }) =>
    (
      size === "small" ? null
        : size === "middle" ? "scale(1.375)"
          : "scale(1.6)"
    )};
  margin-left: ${({ size }) =>
    (
      size === "small" ? null :
        size === "middle" ? "1em" : "1.5em"
    )};
`;

interface IMyProps extends CheckboxProps {
  name?: string;
  value?: string;
  size?: "small" | "middle" | "large";
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  className?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const CheckboxComp: React.FC<IMyProps> = (props) => {
  const { size, onChange, checked, disabled, onFocus, defaultChecked,
    value, className, name } = props;
  return (
    <MyCheckbox
      name={name}
      value={value}
      onChange={onChange}
      size={size}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      onFocus={onFocus}
      className={className}
    >
      {props.children}
    </MyCheckbox>
  );
};

export default CheckboxComp;