import React from "react";
import styled from "styled-components";

const Container = styled.p`
  color: #ff3333;
  margin: 0;
  font-size: 0.9em;

  &:first-letter {
    text-transform: uppercase;
  }
`;

interface IProps {
  msg?: string;
}

const InputErrorMessage: React.FC<IProps> = (props) => {
  const { msg } = props;

  return msg ? <Container>{msg}</Container> : null;
};

export default InputErrorMessage;
