export const STOCK_TYPE = {
  LIMITED: "LIMITED" as const,
  UNLIMITED: "UNLIMITED" as const,
};

export const stockType = Object.values(STOCK_TYPE);
export type TStockType = typeof stockType[number];

export interface IRFID {
  _id: string;
  name?: string;
  designation?: string;
  designationId?: string;
  physicalId: string;
  institutionId: string;
  organizationId: string;
  stockType: TStockType;
  isActive: boolean;
  point: number;
  isFree: boolean;
}

export interface IRFIDCreate {
  name?: string;
  designation?: string;
  designationId?: string;
  physicalId: string;
  stockType: TStockType;
  point: number;
  isFree: boolean;
}

export interface IRFIDUpdate {
  name?: string;
  designation?: string;
  designationId?: string;
  stockType: TStockType;
  isActive?: boolean;
  point: number;
  isFree: boolean;
}
