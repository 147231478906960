import React from "react";
import { AutoComplete, Input } from "antd";
import styled from "styled-components";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const Textfield = styled(AutoComplete)`
  width: 100%;
`;

interface IProps {
  open?: boolean;
  name?: string;
  size?: SizeType;
  options: string[];
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (name: string | undefined, key: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onSelect?: (value: string) => void;
}

const AutoCompleteTextfield: React.FC<IProps> = (props) => {
  const {
    open,
    size,
    name,
    options,
    placeholder,
    value,
    disabled,
    onChange,
    onFocus,
    onBlur,
    onSelect,
  } = props;

  const opts = options.map((option) => ({ value: option }));

  function handleChange(value: string) {
    if (onChange) onChange(name, value);
  }

  return (
    <Textfield
      open={open}
      options={opts}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSelect={onSelect}
      placeholder={placeholder}
      disabled={disabled}
      filterOption={(inputValue, option: any) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    >
      <Input size={size || "large"} />
    </Textfield>
  );
};

export default AutoCompleteTextfield;
