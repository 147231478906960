import { stockType } from "src/types/rfid";
import * as yup from "yup";
import { yupSchemaWrapper } from "./validatorHelpers";

export const validateRFIDCreate = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string(),
    designation: yup.string(),
    designationId: yup.string(),
    physicalId: yup.string().required("Please enter a physical ID"),
    stockType: yup.string().oneOf(stockType).required("Enter a stock type"),
  })
);

export const validateRFIDUpdate = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string(),
    designation: yup.string(),
    designationId: yup.string(),
    stockType: yup.string().oneOf(stockType).required("Enter a stock type"),
    isActive: yup.boolean()
  })
);
