import React, { ReactNode } from "react";
import { Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";


interface IMyProps {
  size?: SizeType;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPressEnter?: () => void;
  visibilityToggle?: boolean;
}

const PasswordField: React.FC<IMyProps> = (props) => {
  const {
    size, value, disabled, placeholder,
    id, name, prefix, suffix, onChange, onFocus, onPressEnter, visibilityToggle } = props;
  return (
    <Input.Password
      size={size || "large"}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      id={id}
      name={name}
      prefix={prefix}
      suffix={suffix}
      onChange={onChange}
      onFocus={onFocus}
      onPressEnter={onPressEnter}
      visibilityToggle={visibilityToggle}
    />
  );
};

export default PasswordField;