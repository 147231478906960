import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 50px;
`;

const BigPaddedContainer: React.FC<{}> = props => {
  return (
    <Container>
      {props.children}
    </Container>
  );
};

export default BigPaddedContainer;
