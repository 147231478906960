import React from "react";
import styled from "styled-components";
import { Footer } from "../organisms/footer";
import { Header } from "../organisms/header";

const MainContainer = styled.div``;

const MiddleContainer = styled.section`
  width: 100%;
  padding: 2rem;
  margin: auto;
  min-height: calc(100vh - 100px);
  padding-top: 7rem;
`;

const ChildContainer = styled.div`
  width: auto;
  max-width: 1200px;
  margin: auto;
  min-height: calc(100vh - 210px);
`;

const DashboardLayout: React.FC = (props: any) => {
  return (
    <MainContainer>
      <Header />
      <MiddleContainer>
        <ChildContainer>{props.children}</ChildContainer>
      </MiddleContainer>
      <Footer />
    </MainContainer>
  );
};

export default DashboardLayout;
