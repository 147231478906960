import { BASE_URL } from "src/utils/config";

export const getAllRFID = (institutionId: string) => `${BASE_URL}/api/v1/rfid/all/${institutionId}`;

export const getRFIDById = (institutionId: string, rfidId: string) =>
  `${BASE_URL}/api/v1/rfid/${institutionId}/${rfidId}`;

export const createRFID = (institutionId: string) =>
  `${BASE_URL}/api/v1/rfid/create/${institutionId}`;

export const updateRFID = (institutionId: string, rfidID: string) =>
  `${BASE_URL}/api/v1/rfid/update/${institutionId}/${rfidID}`;

export const deleteRFID = (institutionId: string, rfidID: string) =>
  `${BASE_URL}/api/v1/rfid/remove/${institutionId}/${rfidID}`;
