import { IAdmin } from "src/types/admin";

export interface ILocalStorageAdmin {
  authAccessToken: string;
  authRefreshToken: string;
  admin: string;
}

interface ILocalStorageKeys {
  admin: ILocalStorageAdmin;
}

export const LOCAL_STORAGE_KEYS: ILocalStorageKeys = {
  admin: {
    authAccessToken: "vms.admin.authAccessToken",
    authRefreshToken: "vms.admin.authRefreshToken",
    admin: "vms.admin.admin",
  },
};


export const setAdminAndTokensLocal = (
  admin: IAdmin,
  accessToken: string,
  refreshToken: string
) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEYS.admin.authAccessToken, accessToken);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.admin.authRefreshToken,
      refreshToken
    );
    localStorage.setItem(LOCAL_STORAGE_KEYS.admin.admin, JSON.stringify(admin));
  } catch (err) {
    // return null;
  }
};


export const getAdminAndTokenLocal = () => {
  try {
    const admin = localStorage.getItem(LOCAL_STORAGE_KEYS.admin.admin);
    const authAccessToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.admin.authAccessToken
    );
    const authRefreshToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.admin.authRefreshToken
    );
    if (admin && authAccessToken) {
      const jsonAdmin = JSON.parse(admin);
      return {
        authAccessToken,
        authRefreshToken,
        admin: jsonAdmin,
      };
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};


export const getTokensLocalAdmin = () => {
  try {
    const authAccessToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.admin.authAccessToken
    );
    const authRefreshToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.admin.authRefreshToken
    );
    if (authAccessToken && authRefreshToken) {
      return {
        authAccessToken,
        authRefreshToken,
      };
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const removeLocalAdminAndToken = () => {
  try {
    // admin
    localStorage.removeItem(LOCAL_STORAGE_KEYS.admin.authAccessToken);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.admin.authRefreshToken);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.admin.admin);
  } catch (err) {
    //
  }
};


export const removeAllDataLocal = () => {
  try {
    const keyValuesAdmin = Object.entries(LOCAL_STORAGE_KEYS.admin);
    keyValuesAdmin.forEach((myKeyValue) => {
      const value = myKeyValue[1];
      localStorage.removeItem(value);
    });
  } catch (err) {
    //
  }
};
