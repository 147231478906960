import React, { useState } from "react";
import { ColumnContainer } from "../../atoms/grid";
import styled from "styled-components";
import { SolidButton } from "src/components/atoms/buttons";
import { Writing4 } from "src/components/atoms/texts/writing";
import { Space } from "antd";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import { ILoginInfo } from "src/api/admin/adminApi";
import LOGO from "src/assets/images/logo.png";
import { Gap } from "src/components/atoms/spaces";
import { validateAdminLogin } from "src/validators/adminValidator";
import { PasswordFieldForm, TextFieldForm } from "src/components/molecules/inputfields";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled(ColumnContainer)`
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
`;

const FormBox = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

const LoginBox = styled.div`
  width: 100%;
  margin-top: 2em;
`;

const LoginForm = styled.form`
  width: 100%;
  padding-top: 1rem;
`;

const LoginButton = styled(SolidButton)`
  width: 100%;
`;

const Image = styled.img`
  max-width: 11rem;
  max-height: 10rem;
`;

const LogIn: React.FC = () => {
  const { loginAdminApiAction } = useAdminAuthContext();

  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const [loginInputs, setLoginInputs] = useState<ILoginInfo>({
    username: "",
    password: "",
  });

  const [error, setError] = useState<any>({});

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setLoginInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onLoginFinish = () => {
    setLoginLoading(false);
  }

  const onLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoginLoading(true);
    const { errors, isValid } = await validateAdminLogin(loginInputs);
    if (isValid) {
      setError({});
      loginAdminApiAction(loginInputs, undefined, onLoginFinish);
    } else {
      setLoginLoading(false);
      if (!isValid && errors) {
        setError(errors);
        return;
      }
    }
  };

  return (
    <Wrapper>
      <Container>
        <Image alt="VMS logo" src={LOGO} />
        <Gap height="1rem" />
        <Space>
          <Writing4>Login to continue to vending machine admin dashboard</Writing4>
        </Space>

        <LoginForm onSubmit={onLogin}>
          <FormBox>
            <TextFieldForm
              labelText="Username"
              labelSize={1}
              placeholder="username"
              name="username"
              value={loginInputs.username}
              onChange={onChange}
              errorMessage={error["username"]}
            />
          </FormBox>
          <FormBox>
            <PasswordFieldForm
              labelText="Password"
              labelSize={1}
              placeholder="password"
              name="password"
              value={loginInputs.password}
              onChange={onChange}
              errorMessage={error["password"]}
            />
          </FormBox>
          <LoginBox>
            <LoginButton type="submit" loading={loginLoading} disabled={loginLoading}>
              LOGIN
            </LoginButton>
          </LoginBox>
        </LoginForm>
      </Container>
    </Wrapper>
  );
};

export { LogIn };
