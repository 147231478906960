import styled from "styled-components";

// 16px (semibold)
// 16px (Medium)
// 14px (Medium)
// 14px (Regular)
// 12px (Regular)

const Writing1 = styled.p<{ align?: string }>`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0rem;
  text-align: ${({ align }) => (align ? align : "left")};
`;

const Writing2 = styled.p<{ align?: string }>`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0rem;
  text-align: ${({ align }) => (align ? align : "left")};
`;

const Writing3 = styled.p<{ align?: string }>`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0rem;
  text-align: ${({ align }) => (align ? align : "left")};
`;

const Writing4 = styled.p<{ align?: string }>`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0rem;
  text-align: ${({ align }) => (align ? align : "left")};
`;

const Writing5 = styled.p<{ align?: string }>`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0rem;
  text-align: ${({ align }) => (align ? align : "left")};
`;

export { Writing1, Writing2, Writing3, Writing4, Writing5 };
