import { AxiosError } from "axios";
import { BASE_URL } from "src/utils/config";

export type ILoginAdminApiError = AxiosError<{
  message: string;
  username?: string;
  password?: string;
  verified?: string;
}>;

export type IRefreshAdminApiError = AxiosError<{
  message: string;
}>;

export const ADMIN_LOGIN = () => `${BASE_URL}/api/v1/admin/login`;

// create admins
export const createSuperAdmin = () => `${BASE_URL}/api/v1/admin/super/create`;
export const createOrganizationAdmin = (organizationId: string) =>
  `${BASE_URL}/api/v1/admin/organization/create/${organizationId}`;
export const createInstitutionAdmin = (institutionId: string) =>
  `${BASE_URL}/api/v1/admin/institution/create/${institutionId}`;

// get admins
export const getAllOrganizationAdmins = (organizationId: string) =>
  `${BASE_URL}/api/v1/admin/organization/all/${organizationId}`;
export const getAllInstitutionAdmins = (institutionId: string) =>
  `${BASE_URL}/api/v1/admin/institution/all/${institutionId}`;

// delete admins
export const deleteOrganizationAdmin = (organizationId: string, adminId: string) =>
  `${BASE_URL}/api/v1/admin/organization/remove/${organizationId}/${adminId}`;
export const deleteInstitutionAdmin = (institutionId: string, adminId: string) =>
  `${BASE_URL}/api/v1/admin/institution/remove/${institutionId}/${adminId}`;
