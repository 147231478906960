// overall
export const HOME = () => "/";

export const SUPER_ADMIN_DASHBOARD = () => "/dashboard";

export const ORGANIZATION_ADMIN_DASHBOARD = (organizationId: string) =>
  `/organization/${organizationId}`;

export const INSTITUTION_ADMIN_DASHBOARD = (institutionId: string) =>
  `/institution/${institutionId}`;

export const PAYMENT_STATUS_SUCCESS = `/payment/status/success`;
export const PAYMENT_STATUS_FAILURE = `/payment/status/failed`;
export const PAYMENT_PROCESS_FAILED = `/payment/process/failed`;
