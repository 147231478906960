import React from "react";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { SelectArrowIcon } from "../../../assets/icons";

const { Option } = Select;
interface IProps {
  name?: string;
  placeholder?: string;
  size?: SizeType;
  options: string[];
  value?: number;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (name: string | undefined, key: number) => void;
  flex?: 1;
}

const AutoSuggestSelectComponent: React.FC<IProps> = (props) => {
  const {
    name,
    size,
    options,
    value,
    onChange,
    onFocus,
    placeholder,
    disabled,
  } = props;
  const val = value !== undefined ? options[value] : undefined;
  const children = [];

  for (let i = 0; i < options.length; i++) {
    children.push(
      <Option key={i} value={options[i]}>
        {options[i]}
      </Option>
    );
  }

  function handleChange(value: any, option: any) {
    if (onChange) {
      if (!value) onChange(name, -1);
      else onChange(name, option.key);
    }
  }

  return (
    <Select
      size={size || "large"}
      value={val}
      suffixIcon={SelectArrowIcon}
      showArrow
      onChange={handleChange}
      onFocus={onFocus}
      disabled={disabled}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear
      placeholder={placeholder}
    >
      {children}
    </Select>
  );
};

export default AutoSuggestSelectComponent;
