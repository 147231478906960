import { BASE_URL } from "src/utils/config";

export const getAllInstitutions = (organizationId: string) =>
  `${BASE_URL}/api/v1/institution/all/${organizationId}`;

export const getInstitutionById = (institutionId: string) => 
  `${BASE_URL}/api/v1/institution/${institutionId}`;

export const createInstitution = (organizationId: string) =>
  `${BASE_URL}/api/v1/institution/create/${organizationId}`;

export const updateInstitution = (institutionId: string) =>
  `${BASE_URL}/api/v1/institution/update/${institutionId}`;

export const deleteInstitution = (institutionId: string) =>
  `${BASE_URL}/api/v1/institution/remove/${institutionId}`;
