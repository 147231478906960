import { Button } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { Heading2 } from "../../atoms/texts/heading";
import { Writing2, Writing5 } from "../../atoms/texts/writing";
import DivaLogo from "../../../assets/images/diva_logo.png";
import FailureIcon from "../../../assets/images/failure_icon.png";
import { Gap } from "../../atoms/spaces";
import { theme } from "../../../assets/styles/styled-theme";
import { BASE_URL } from "../../../utils/config";
import { ExclamationCircleIcon } from "../../../assets/icons";

const MainContainer = styled.div`
  width: 600px;
  max-width: 100%;
  height: calc(100vh - 52px);
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Heading2}, ${Writing2}, ${Writing5} {
    text-align: center;
  }
`;

const TopContainer = styled.div`
  flex: 1;
`;

const BottomContainer = styled.div`
  padding-top: 0.5rem;
  border-top: 2px solid ${({ theme }) => theme.colors.gray4};
`;

const LogoImage = styled.img`
  width: 100px;
  max-width: 80%;
  max-height: 120px;
  object-fit: contain;
`;

const StatusImage = styled.img`
  width: 90px;
  object-fit: contain;
`;

const MessageContainer = styled.div`
  max-width: 85%;
  margin: auto;
`;

const ErrorMessageContainer = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primaryColor};
  background-color: ${({ theme }) => theme.colors.dangerLight};

  ${Writing2} {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

const ServerErrorContainer = styled.div`
  padding: 1rem;
  border: 1px dashed ${({ theme }) => theme.colors.gray7};
  background-color: ${({ theme }) => theme.colors.facebookBorder};
`;

const DispenseButton = styled(Button)`
  border-radius: 2px;

  &,
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.divaColor};
    border-color: ${({ theme }) => theme.colors.divaColor};
    color: ${({ theme }) => theme.colors.bodyColor};
  }
`;

const ButtonCaption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.25rem;
  margin-bottom: 0.5rem;

  & svg {
    margin-top: 2px;
  }

  ${Writing5} {
    text-align: left;
  }
`;

const PaymentStatusFailurePage: React.FC = (props) => {
  const params = new URLSearchParams((props as any).location.search);
  const message = params.get("message");
  const machineId = params.get("machineId");

  const onTryAgainClick = useCallback(() => {
    const replaceUrl = `${BASE_URL}/api/v1/payment/bKash-pgw?machineId=${machineId}`;
    location.replace(replaceUrl);
  }, [machineId]);

  return (
    <MainContainer>
      <TopContainer>
        <LogoImage src={DivaLogo} />
        <Gap height="3rem" />

        <StatusImage src={FailureIcon} />

        <Gap height="2rem" />
        <MessageContainer>
          <Heading2 color={theme.colors.headingColor}>We are sorry!</Heading2>
          <Gap height="0.5rem" />

          <ErrorMessageContainer>
            <Writing2>Your bKash payment has been failed to process at this moment</Writing2>
          </ErrorMessageContainer>

          {message && (
            <>
              <Gap height="0.5rem" />
              <ServerErrorContainer>{message}</ServerErrorContainer>
            </>
          )}
          <Gap height="2.5rem" />
          <DispenseButton type="primary" size="large" onClick={onTryAgainClick}>
            Try again
          </DispenseButton>
          <Gap height="1rem" />
          <ButtonCaption>
            <ExclamationCircleIcon />
            <Writing5>
              Still, if you are being unsuccessful for a while, please contact authority.
            </Writing5>
          </ButtonCaption>
        </MessageContainer>
      </TopContainer>

      <BottomContainer>
        <Writing5>
          Made with ❤️ by&nbsp;
          <a href="https://headless.ltd/" target="__blank" rel="noreferrer">
            Headless Technologies Limited
          </a>
          &nbsp; in collaboration with bKash.
        </Writing5>
      </BottomContainer>
    </MainContainer>
  );
};

export default PaymentStatusFailurePage;
