import * as yup from "yup";
import { yupSchemaWrapper } from "./validatorHelpers";

export const validateMachineCreate = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string(),
    physicalId: yup.string().required("Physical Id is required"),
    stocks: yup.array().of(
      yup.object().shape({
        rowNo: yup.number().min(1, "Row no. cannot be less than 1").required("Row no. is required"),
        columnNo: yup
          .number()
          .min(1, "Column no. cannot be less than 1")
          .required("Column no. is required"),
        productCount: yup
          .number()
          .min(0, "Enter a valid value")
          .required("Product count is required"),
      })
    ),
    price: yup.number().typeError("Price must be a number"),
    point: yup.number().typeError("Price must be a number"),
  })
);

export const validateMachineUpdate = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string(),
    stocks: yup.array().of(
      yup.object().shape({
        rowNo: yup.number().min(1, "Row no. cannot be less than 1").required("Row no. is required"),
        columnNo: yup
          .number()
          .min(1, "Column no. cannot be less than 1")
          .required("Column no. is required"),
        productCount: yup
          .number()
          .min(0, "Enter a valid value")
          .required("Product count is required"),
      })
    ),
    price: yup.number().typeError("Price must be a number").required(),
  })
);
