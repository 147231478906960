import styled from "styled-components";
import React from "react";
import { Writing4 } from "../texts/writing";
import { Tooltip } from "antd";

const MyDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MySpan = styled.span`
  padding-left: 8px;
  cursor: auto;
`;

const ActiveSpan = styled.span<{
  color: "successColor" | "dangerColor" | "upcomingColor" | "inProgressColor" | undefined;
}>`
  background: ${({ color, theme }) => {
    return color ? (color ? theme.colors[color] : null) : null;
  }};
  border-radius: 8px;
  display: inline-block;
  height: 8px;
  width: 8px;
`;

interface IProps {
  status?: "running" | "expired" | "upcoming" | "inProgress";
  label?: string;
  className?: string;
  title?: string;
}

const Status: React.FC<IProps> = (props) => {
  const { status, label, className, title } = props;

  let color: "successColor" | "dangerColor" | "upcomingColor" | "inProgressColor" | undefined;

  switch (status) {
    case "running":
      color = "successColor";
      break;
    case "expired":
      color = "dangerColor";
      break;
    case "upcoming":
      color = "upcomingColor";
      break;
    case "inProgress":
      color = "inProgressColor";
      break;
  }

  return (
    <Tooltip title={title} placement="topLeft">
      <MyDiv className={className}>
        <ActiveSpan color={color} />
        {label ? (
          <MySpan>
            <Writing4>{label}</Writing4>
          </MySpan>
        ) : null}
      </MyDiv>
    </Tooltip>
  );
};

export default Status;
