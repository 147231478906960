import React, { Context, createContext, useCallback, useContext, useState } from "react";
import {
  createInstitutionAdminApi,
  deleteInstitutionAdminApi,
  getAllInstitutionAdminsApi,
} from "src/api/admin/adminApi";
import { ICommonApiError } from "src/api/apiRequest";
import { handlePrivateApiError } from "src/api/errorHandlers";
import {
  createInstitutionApi,
  deleteInstitutionApi,
  getAllInstitutionsApi,
  getInstitutionByIdApi,
  updateInstitutionApi,
} from "src/api/institution/institutionApi";
import { ICreateAdmin, IInstitutionAdmin } from "src/types/admin";
import { IInstitution, IInstitutionCreate, IInstitutionUpdate } from "src/types/institution";
import { useAdminAuthContext } from "../admin/AuthContext";
import { useOrganizationsContext } from "../organization/OrganizationsContext";
import { showErrorToastAction } from "../toast";

interface IInstitutionsContextProps {
  institutionsLoading: boolean;
  institutionsChangeLoading: boolean;
  institutionAdminsLoading: boolean;
  institutionAdminsChangeLoading: boolean;
  currentInstitution: IInstitution | undefined;
  clearCurrentInstitution: () => void;
  institutions: IInstitution[];
  institutionAdmins: IInstitutionAdmin[];
  getAllInstitutionsApiAction: (organizationId: string) => void;
  getInstitutionByIdApiAction: (institutionId: string) => Promise<void>;
  createInstitutionApiAction: (
    organizationId: string,
    createData: IInstitutionCreate
  ) => Promise<void>;
  updateInstitutionApiAction: (
    institutionId: string,
    editData: IInstitutionUpdate
  ) => Promise<void>;
  deleteInstitutionApiAction: (institutionId: string) => Promise<void>;
  getAllInstitutionAdminApiAction: (institutionId: string) => void;
  createInstitutionAdminApiAction: (
    institutionId: string,
    createData: ICreateAdmin
  ) => Promise<void>;
  deleteInstitutionAdminApiAction: (institutionId: string, adminId: string) => Promise<void>;
}

export const InstitutionsContext = createContext<IInstitutionsContextProps | undefined>(
  undefined
) as Context<IInstitutionsContextProps>;

const InstitutionsProvider: React.FC = (props: any) => {
  const [institutionsLoading, setInstitutionsLoading] = useState<boolean>(false);
  const [institutionsChangeLoading, setInstitutionsChangeLoading] = useState<boolean>(false);
  const [institutionAdminsLoading, setInstitutionAdminsLoading] = useState<boolean>(false);
  const [institutionAdminsChangeLoading, setInstitutionAdminsChangeLoading] =
    useState<boolean>(false);
  const [institutions, setInstitutions] = useState<IInstitution[]>([]);
  const [currentInstitution, setCurrentInstitution] = useState<IInstitution | undefined>(undefined);
  const [institutionAdmins, setInstitutionAdmins] = useState<IInstitutionAdmin[]>([]);

  // Log out action from Auth context to use in privateErrors
  const { logoutAdminApiAction } = useAdminAuthContext();
  const { currentOrganization, getOrganizationByIdApiAction } = useOrganizationsContext();

  /**
   * Institution Api Actions
   * Get all
   * Get By ID
   * Crete
   * Update
   * Delete
   */
  const getAllInstitutionsApiAction = useCallback(
    (organizationId: string) => {
      if (currentOrganization?._id !== organizationId) {
        setInstitutionsLoading(true);
        getAllInstitutionsApi(organizationId)
          .then((res) => {
            setInstitutions(res.data);
          })
          .catch((err: ICommonApiError) => {
            const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
            showErrorToastAction({
              message: data?.message || error || "Failed to fetch institutions",
            });
            // setInstitutions([]);
          })
          .finally(() => {
            setInstitutionsLoading(false);
          });
      }
    },
    [logoutAdminApiAction, currentOrganization]
  );

  const clearCurrentInstitution = () => {
    setCurrentInstitution(undefined);
  };

  const getInstitutionByIdApiAction = useCallback(
    async (institutionId: string) => {
      if (currentInstitution?._id === institutionId) {
        return;
      }

      setCurrentInstitution(undefined);

      try {
        const res = await getInstitutionByIdApi(institutionId);
        setCurrentInstitution(res.data);
        await getOrganizationByIdApiAction(res.data.organizationId);
      } catch (err) {
        const { error, data } = handlePrivateApiError(err as ICommonApiError, logoutAdminApiAction);
        showErrorToastAction({
          message: data?.message || error || "Failed to fetch institution",
        });
        setCurrentInstitution(undefined);
      }
    },
    [logoutAdminApiAction, currentInstitution, getOrganizationByIdApiAction]
  );

  const createInstitutionApiAction = (organizationId: string, createData: IInstitutionCreate) => {
    return new Promise<void>((resolve) => {
      setInstitutionsChangeLoading(true);
      createInstitutionApi(organizationId, createData)
        .then((res) => {
          setInstitutions((prev) => [...prev, res.data]);
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to create institution",
          });
        })
        .finally(() => {
          setInstitutionsChangeLoading(false);
          resolve();
        });
    });
  };

  const updateInstitutionApiAction = (institutionId: string, editData: IInstitutionUpdate) => {
    return new Promise<void>((resolve) => {
      setInstitutionsChangeLoading(true);
      updateInstitutionApi(institutionId, editData)
        .then((res) => {
          if (currentInstitution?._id === institutionId) setCurrentInstitution(res.data);
          setInstitutions((prev) => {
            const index = prev.findIndex((institution) => institution._id === institutionId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to update institution",
          });
        })
        .finally(() => {
          setInstitutionsChangeLoading(false);
          resolve();
        });
    });
  };

  const deleteInstitutionApiAction = (institutionId: string) => {
    return new Promise<void>((resolve) => {
      setInstitutionsChangeLoading(true);
      deleteInstitutionApi(institutionId)
        .then(() => {
          if (currentInstitution?._id === institutionId) setCurrentInstitution(undefined);
          setInstitutions((prev) => {
            const filteredData = prev.filter((institution) => institution._id !== institutionId);
            return [...filteredData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to delete institution",
          });
        })
        .finally(() => {
          setInstitutionsChangeLoading(false);
          resolve();
        });
    });
  };

  /**
   * Institution Admin Api Actions
   * Get all
   * Crete
   * Delete
   */

  const getAllInstitutionAdminApiAction = useCallback(
    (institutionId: string) => {
      if (currentInstitution?._id !== institutionId) {
        setInstitutionAdminsLoading(true);
        getAllInstitutionAdminsApi(institutionId)
          .then((res) => {
            setInstitutionAdmins(res.data);
          })
          .catch((err: ICommonApiError) => {
            const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
            showErrorToastAction({
              message: data?.message || error || "Failed to fetch admins",
            });
          })
          .finally(() => {
            setInstitutionAdminsLoading(false);
          });
      }
    },
    [logoutAdminApiAction, currentInstitution]
  );

  const createInstitutionAdminApiAction = (institutionId: string, createData: ICreateAdmin) => {
    return new Promise<void>((resolve) => {
      setInstitutionAdminsChangeLoading(true);
      createInstitutionAdminApi(institutionId, createData)
        .then((res) => {
          setInstitutionAdmins((prev) => [...prev, res.data]);
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to create admin",
          });
        })
        .finally(() => {
          setInstitutionAdminsChangeLoading(false);
          resolve();
        });
    });
  };

  const deleteInstitutionAdminApiAction = (institutionId: string, adminId: string) => {
    return new Promise<void>((resolve) => {
      setInstitutionAdminsChangeLoading(true);
      deleteInstitutionAdminApi(institutionId, adminId)
        .then(() => {
          setInstitutionAdmins((prev) => {
            const filteredData = prev.filter((admin) => admin._id !== adminId);
            return [...filteredData];
          });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to delete admin",
          });
        })
        .finally(() => {
          setInstitutionAdminsChangeLoading(false);
          resolve();
        });
    });
  };

  const contextProps = {
    institutionsLoading,
    institutionsChangeLoading,
    institutionAdminsLoading,
    institutionAdminsChangeLoading,
    currentInstitution,
    clearCurrentInstitution,
    institutions,
    institutionAdmins,
    getAllInstitutionsApiAction,
    getInstitutionByIdApiAction,
    createInstitutionApiAction,
    updateInstitutionApiAction,
    deleteInstitutionApiAction,
    getAllInstitutionAdminApiAction,
    createInstitutionAdminApiAction,
    deleteInstitutionAdminApiAction,
  };

  return (
    <InstitutionsContext.Provider value={contextProps}>
      {props.children}
    </InstitutionsContext.Provider>
  );
};

export default InstitutionsProvider;

export const useInstitutionsContext = () => {
  const context = useContext(InstitutionsContext);
  if (!context) {
    throw new Error("useInstitutionsContext must be used within a InstitutionsProvider");
  }
  return context;
};
