import moment, { unitOfTime } from "moment";

export function getTimeDiff(dateTime: Date, timeUnit: unitOfTime.Diff) {
  return moment().diff(moment(dateTime), timeUnit);
}

export function getStartOfCurrentMonth() {
  return moment().clone().startOf("month").format("YYYY-MM-DD");
}

export function getToday() {
  return moment().format("YYYY-MM-DD");
}
