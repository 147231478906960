import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 30px;
  border:${({ theme }) =>
    (
      "1px solid " + theme.colors.borderLight
    )} ;
  box-sizing: border-box;
`;

interface IMyProps {
  className?: string;
  id?: string;
}

const FormBorderContainer: React.FC<IMyProps> = props => {
  const { className, children, id } = props;
  return (
    <Container className={className} id={id}>
      {children}
    </Container>
  );
};

export default FormBorderContainer;
