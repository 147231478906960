import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1000px;
`;

interface IProps {
  className?: string;
}

const FixContentContainer: React.FC<IProps> = props => {

  const { className } = props;

  return (
    <Container className={className}>
      {props.children}
    </Container>
  );
};

export default FixContentContainer;
