import { institutionCategory } from "src/types/institution";
import * as yup from "yup";
import { yupSchemaWrapper } from "./validatorHelpers";

export const validateInstitutionCreate = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string().required("Name is required"),
    category: yup
      .string()
      .required("Category is required")
      .oneOf(institutionCategory, "Not a valid category"),
    address: yup.string().required("Address is required"),
    // pointsEnabled: yup.boolean(),
  })
);

export const validateInstitutionUpdate = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string(),
    category: yup.string().oneOf(institutionCategory, "Not a valid category"),
    address: yup.string(),
    // pointsEnabled: yup.boolean(),
  })
);
