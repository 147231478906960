import React, { useEffect, useState } from "react";
import { Loading } from "src/components/atoms/displays";
import { Writing3 } from "src/components/atoms/texts/writing";
import { Container } from "src/components/atoms/grid";
import { useDispenseContext } from "../../../contexts/dispense/DispenseContext";
import { ListDiv, ListItem, TBody, THead, THeader } from "./common";
import { Gap } from "../../atoms/spaces";
import { Heading1 } from "../../atoms/texts/heading";
import { useMachineContext } from "../../../contexts/machine/MachineContext";
// import { useRFIDContext } from "../../../contexts/rfid/RFIDContext";
// import { useOrganizationsContext } from "../../../contexts/organization/OrganizationsContext";
import { DateRangepicker } from "../../molecules/dates";
import moment from "moment";

interface IProps {
  institutionId: string;
}

const Overview: React.FC<IProps> = ({ institutionId }) => {
  const { dispenseStats, dispensesLoading, getSuccessfulDispenseStatsApiAction } =
    useDispenseContext();

  const { machines } = useMachineContext();
  // const { rfids } = useRFIDContext();
  // const { currentOrganization } = useOrganizationsContext();
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(1, "months"),
    end: moment(),
  });

  const getMachineName = (machineId: string) => {
    const machine = machines.find((m) => m._id === machineId);
    return machine?.name;
  };

  // const getRFIDHolderName = (rfidId: string) => {
  //   const rfid = rfids.find((r) => r._id === rfidId);
  //   return rfid?.name;
  // };

  // const getProductSizeName = (sizeId: string) => {
  //   const size = currentOrganization?.sizeTypes.find((s) => s._id === sizeId);
  //   return size?.sizeName;
  // };

  const onDateRangeChange = (name: string, value: any) => {
    if (name) {
      setDateRange((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    getSuccessfulDispenseStatsApiAction(
      institutionId,
      dateRange.start.toString(),
      dateRange.end.toString()
    );
  }, [getSuccessfulDispenseStatsApiAction, institutionId, dateRange]);

  return (
    <Container>
      <DateRangepicker
        name="dateRange"
        labelText="Select a range"
        values={[dateRange.start, dateRange.end]}
        onChange={(_name, newDate) => {
          if (newDate) {
            onDateRangeChange("start", newDate[0]);
            onDateRangeChange("end", newDate[1]);
          }
        }}
      />
      {!dispensesLoading ? (
        <Container>
          {dispenseStats.map(({ type, machines }, i) => (
            <div key={i}>
              <Heading1>{type}</Heading1>
              <Gap height="0.5rem" />

              <THeader columns={2}>
                <THead>
                  <Writing3>Machine Name</Writing3>
                </THead>
                <THead>
                  <Writing3>Successfully dispensed</Writing3>
                </THead>
              </THeader>
              <TBody>
                {machines.map(({ machineId, count }) => (
                  <ListItem key={machineId} columns={2}>
                    <ListDiv>
                      <Writing3>{getMachineName(machineId)}</Writing3>
                    </ListDiv>
                    <ListDiv>
                      <Writing3>{count}</Writing3>
                    </ListDiv>
                  </ListItem>
                ))}
              </TBody>
              <Gap height="1rem" />
            </div>
          ))}
        </Container>
      ) : (
        <Loading size={"60px"} />
      )}
    </Container>
  );
};

export default Overview;
