import { Modal } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading } from "src/components/atoms/displays";
import { GridContainer } from "src/components/atoms/grid";
import { Gap } from "src/components/atoms/spaces";
import { Heading2 } from "src/components/atoms/texts/heading";
import { Writing1, Writing2 } from "src/components/atoms/texts/writing";
import { NameCard } from "src/components/molecules/cards";
import { TextFieldForm } from "src/components/molecules/inputfields";
import { useOrganizationsContext } from "src/contexts/organization/OrganizationsContext";
import { ICreateUpdateOrganization, IOrganization } from "src/types/organization";
import { firstLetterToUpperCase } from "src/utils/strings";
import { validateOrganization } from "src/validators/organizationValidator";
import styled from "styled-components";

const Container = styled.div``;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0.75rem;
  }
`;

const StyledDeleteModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0.75rem;
    }
  }
`;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const initialInputData: ICreateUpdateOrganization = {
  name: "",
  description: "",
  type: "",
};

interface IProps {
  organizations: IOrganization[];
}

const Organizations: React.FC<IProps> = ({ organizations }) => {
  const {
    organizationsLoading,
    organizationsChangeLoading,
    createOrganizationApiAction,
    updateOrganizationApiAction,
    deleteOrganizationApiAction,
  } = useOrganizationsContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IOrganization;
    type: "create" | "update" | "delete" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<ICreateUpdateOrganization>(initialInputData);

  const [error, setError] = useState<any>({});

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData(initialInputData);
    setError({});
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCreateModal = () => {
    setModal({ visible: true, type: "create" });
  };

  const onCreateOrganization = async () => {
    const { errors, isValid } = await validateOrganization(inputData);
    if (isValid) {
      setError({});
      await createOrganizationApiAction(inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleUpdateModal = (data: IOrganization) => {
    setModal({ visible: true, data: data, type: "update" });
    setInputData({
      name: data.name,
      description: data.description,
      type: data.type,
    });
  };

  const onUpdateOrganization = async () => {
    const { errors, isValid } = await validateOrganization(inputData);
    if (isValid && modal.data?._id) {
      setError({});
      await updateOrganizationApiAction(modal.data._id, inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleDeleteModal = (data: IOrganization) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteOrganization = async () => {
    if (modal.data?._id) {
      await deleteOrganizationApiAction(modal.data._id);
      handleModalClose();
    }
  };

  const history = useHistory();
  const handleClick = (organizationId: string) => {
    history.push(`/organization/${organizationId}`);
  };

  return (
    <Container>
      <StyledModal
        title={firstLetterToUpperCase(modal.type) + " Organization"}
        visible={modal.visible && modal.type !== "delete"}
        confirmLoading={organizationsChangeLoading}
        onOk={
          modal.type === "create"
            ? () => onCreateOrganization()
            : modal.type === "update"
            ? () => onUpdateOrganization()
            : () => null
        }
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          <Writing1>Name</Writing1>
          <TextFieldForm
            placeholder="name"
            name="name"
            value={inputData.name}
            onChange={handleChangeText}
            errorMessage={error["name"]}
          />
          <Gap height={"1rem"} />
          <Writing1>Description</Writing1>
          <TextFieldForm
            placeholder="description"
            name="description"
            value={inputData.description}
            onChange={handleChangeText}
            errorMessage={error["description"]}
          />
          <Gap height={"1rem"} />
          <Writing1>Type</Writing1>
          <TextFieldForm
            placeholder="type"
            name="type"
            value={inputData.type}
            onChange={handleChangeText}
            errorMessage={error["type"]}
          />
        </>
      </StyledModal>

      <StyledDeleteModal
        visible={modal.visible && modal.type === "delete"}
        title={firstLetterToUpperCase(modal.type) + " Organization"}
        onOk={() => onDeleteOrganization()}
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={organizationsChangeLoading}
      >
        <Writing2>Are you sure you want to delete organization "{modal.data?.name}"</Writing2>
      </StyledDeleteModal>

      <FlexContainer>
        {organizations ? (
          <SolidButton onClick={handleCreateModal}>Add Organization</SolidButton>
        ) : null}
      </FlexContainer>

      <Gap height={"1.5rem"} />

      {organizationsLoading ? (
        <Loading size="60px" />
      ) : organizations.length ? (
        <GridContainer>
          {organizations.map((organization) => (
            <NameCard
              key={organization._id}
              name={organization.name}
              onClick={() => handleClick(organization._id)}
              onEdit={() => handleUpdateModal(organization)}
              onDelete={() => handleDeleteModal(organization)}
            />
          ))}
        </GridContainer>
      ) : (
        <NotFound>
          <Heading2>No organizations found</Heading2>
        </NotFound>
      )}
    </Container>
  );
};

export default Organizations;
