import { Select, Space } from "antd";
import React, { useState } from "react";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading } from "src/components/atoms/displays";
import { Container } from "src/components/atoms/grid";
import { Gap } from "src/components/atoms/spaces";
import { Writing2, Writing3 } from "src/components/atoms/texts/writing";
import { TextFieldForm } from "src/components/molecules/inputfields";
import { useMachineContext } from "src/contexts/machine/MachineContext";
import { firstLetterToUpperCase } from "src/utils/strings";
import { IProductSize } from "../../../types/machine";
import { validateProductSize } from "../../../validators/organizationValidator";
import {
  DeleteIcon,
  EditIcon,
  FlexContainer,
  ListDiv,
  ListItem,
  StyledDeleteModal,
  StyledModal,
  TBody,
  THead,
  THeader,
} from "../institutionAdminDashboard/common";

const initialInputs: IProductSize = {
  sizeId: "",
  sizeName: "",
  price: undefined,
  point: undefined,
};

const ProductSizes: React.FC = () => {
  const {
    productSizeActionLoading,
    currentMachine,
    setCurrentMachine,
    machines,
    addSizeApiAction,
    updateSizeApiAction,
    deleteSizeApiAction,
  } = useMachineContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IProductSize;
    type: "add" | "update" | "delete" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<IProductSize>(initialInputs);

  const [error, setError] = useState<any>({});

  const handleChangeSelectedMachine = (value: string) => {
    setCurrentMachine(value);
  };

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData(initialInputs);
    setError({});
  };

  const handleChangeSizeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleAddModal = () => {
    setModal({ visible: true, type: "add" });
    setInputData(initialInputs);
  };

  const onAddSize = async () => {
    const { errors, isValid } = await validateProductSize(inputData);
    if (isValid && currentMachine?._id) {
      setError({});
      await addSizeApiAction(currentMachine?.institutionId, currentMachine?._id, inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleUpdateModal = (size: IProductSize) => {
    setModal({ visible: true, data: size, type: "update" });
    setInputData({
      sizeId: size.sizeId,
      sizeName: size.sizeName,
      price: size.price,
      point: size.point,
    });
  };

  const onUpdateSize = async () => {
    const { errors, isValid } = await validateProductSize(inputData);
    if (isValid && currentMachine?._id && modal.data?._id) {
      setError({});
      await updateSizeApiAction(
        currentMachine?.institutionId,
        currentMachine?._id,
        modal.data._id,
        inputData
      );
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleDeleteModal = (data: IProductSize) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteSize = async () => {
    if (modal.data?._id && currentMachine?._id) {
      await deleteSizeApiAction(currentMachine?.institutionId, currentMachine?._id, modal.data._id);
      handleModalClose();
    }
  };

  return (
    <Container>
      <StyledModal
        title={`${firstLetterToUpperCase(modal.type)}  Size`}
        visible={(modal.visible && modal.type === "add") || modal.type === "update"}
        confirmLoading={productSizeActionLoading}
        onOk={
          modal.type === "add"
            ? () => onAddSize()
            : modal.type === "update"
            ? () => onUpdateSize()
            : () => null
        }
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          {currentMachine && (
            <TextFieldForm
              labelSize={1}
              labelText="Machine"
              value={currentMachine.physicalId}
              disabled={true}
            />
          )}
          <Gap height={"0.75rem"} />
          <TextFieldForm
            labelSize={1}
            labelText="Size ID"
            placeholder="Size ID"
            name="sizeId"
            value={inputData.sizeId}
            disabled={modal.type === "update"}
            onChange={handleChangeSizeForm}
            errorMessage={error["sizeId"]}
          />
          <Gap height={"0.75rem"} />
          <TextFieldForm
            labelSize={1}
            labelText="Size Name"
            placeholder="Size Name"
            name="sizeName"
            value={inputData.sizeName}
            onChange={handleChangeSizeForm}
            errorMessage={error["sizeName"]}
          />
          <Gap height={"0.75rem"} />
          <TextFieldForm
            labelSize={1}
            labelText="Price"
            placeholder="Price"
            name="price"
            value={inputData.price}
            onChange={handleChangeSizeForm}
            errorMessage={error["price"]}
          />
          <Gap height={"0.75rem"} />
          <TextFieldForm
            labelSize={1}
            labelText="Point"
            placeholder="Point"
            name="point"
            value={inputData.point}
            onChange={handleChangeSizeForm}
            errorMessage={error["point"]}
          />
        </>
      </StyledModal>
      <StyledDeleteModal
        visible={modal.visible && modal.type === "delete"}
        title={firstLetterToUpperCase(modal.type) + " size"}
        onOk={modal.type === "delete" ? () => onDeleteSize() : () => null}
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={productSizeActionLoading}
      >
        <Writing2>
          Are you sure you want to {modal.type} this size "{modal.data?.sizeName}"?
        </Writing2>
      </StyledDeleteModal>

      <FlexContainer>
        <SolidButton onClick={() => handleAddModal()} disabled={machines.length === 0}>
          Add Size
        </SolidButton>
      </FlexContainer>

      <Container>
        <Writing3>Select a machine</Writing3>
        <Select
          placeholder="Select a machine"
          size="large"
          value={currentMachine?.physicalId}
          style={{ minWidth: "200px" }}
          onChange={handleChangeSelectedMachine}
          disabled={machines.length === 0}
        >
          {machines.map((machine) => (
            <Select.Option key={machine.physicalId} value={machine.physicalId}>
              {machine.physicalId}
            </Select.Option>
          ))}
        </Select>
        <Gap height={"0.75rem"} />
        {!currentMachine ? (
          <Writing2 align="center">Please select a machine</Writing2>
        ) : (
          <>
            <THeader columns={5}>
              <THead>
                <Writing3>Size ID</Writing3>
              </THead>
              <THead>
                <Writing3>Size Name</Writing3>
              </THead>
              <THead>
                <Writing3>Product Price (Tk)</Writing3>
              </THead>
              <THead>
                <Writing3>Points</Writing3>
              </THead>
              <THead>
                <Writing3>Actions</Writing3>
              </THead>
            </THeader>
            {currentMachine?.sizeTypes && currentMachine?.sizeTypes.length > 0 ? (
              !productSizeActionLoading ? (
                <TBody>
                  {currentMachine?.sizeTypes?.map((size) => (
                    <ListItem key={size?._id} columns={5}>
                      <ListDiv>
                        <Writing3>{size.sizeId}</Writing3>
                      </ListDiv>
                      <ListDiv>
                        <Writing3>{size.sizeName}</Writing3>
                      </ListDiv>
                      <ListDiv>
                        <Writing3>{size.price}</Writing3>
                      </ListDiv>
                      <ListDiv>
                        <Writing3>{size.point}</Writing3>
                      </ListDiv>
                      <ListDiv>
                        <Space size={"large"} align={"center"}>
                          <EditIcon title="Edit size" onClick={() => handleUpdateModal(size)} />
                          <DeleteIcon title="Delete size" onClick={() => handleDeleteModal(size)} />
                        </Space>
                      </ListDiv>
                    </ListItem>
                  ))}
                </TBody>
              ) : (
                <Loading size={"60px"} />
              )
            ) : (
              <>
                <Gap height={"0.75rem"} />
                <Writing2 align="center">No product size found</Writing2>
              </>
            )}
          </>
        )}
      </Container>
    </Container>
  );
};

export default ProductSizes;
