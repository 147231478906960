import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./assets/styles/styled-theme";
import { Routers } from "./HOC/routes";
import "./App.less";
import RootProvider from "./contexts/RootContext";

function App() {
  return (
    <RootProvider>
      <ThemeProvider theme={theme}>
        <Routers />
      </ThemeProvider>
    </RootProvider>
  );
}

export default App;
