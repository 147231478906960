import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  margin: 0 auto;
  width: 100%;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export { GridContainer };