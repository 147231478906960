import React, { Context, createContext, useCallback, useContext, useState } from "react";
import { ICommonApiError } from "src/api/apiRequest";
import { handlePrivateApiError } from "src/api/errorHandlers";
import {
  addSizeApi,
  createMachineApi,
  deleteMachineApi,
  deleteSizeApi,
  getAllMachinesApi,
  updateMachineApi,
  updateSizeApi,
} from "src/api/machine/machineApi";
import { IMachine, IMachineCreateUpdate, IProductSize } from "src/types/machine";
import { useAdminAuthContext } from "../admin/AuthContext";
import { useInstitutionsContext } from "../institution/InstitutionsContext";
import { showErrorToastAction, showToastAction } from "../toast";

interface IMachineContextProps {
  machinesLoading: boolean;
  machinesChangeLoading: boolean;
  productSizeActionLoading: boolean;
  machines: IMachine[];
  currentMachine: IMachine | null;
  getAllMachinesApiAction: (institutionId: string) => Promise<void>;
  createMachineApiAction: (
    institutionId: string,
    createData: IMachineCreateUpdate
  ) => Promise<void>;
  updateMachineApiAction: (
    institutionId: string,
    machineId: string,
    editData: IMachineCreateUpdate
  ) => Promise<void>;
  deleteMachineApiAction: (institutionId: string, machineId: string) => Promise<void>;
  setCurrentMachine: (physicalId: string) => void;
  addSizeApiAction: (institutionId: string, machineId: string, data: IProductSize) => Promise<void>;
  updateSizeApiAction: (
    institutionId: string,
    machineId: string,
    sizeId: string,
    data: IProductSize
  ) => Promise<void>;
  deleteSizeApiAction: (institutionId: string, machineId: string, sizeId: string) => Promise<void>;
}

export const MachineContext = createContext<IMachineContextProps | undefined>(
  undefined
) as Context<IMachineContextProps>;

const MachinesProvider: React.FC = (props: any) => {
  const [machinesLoading, setMachinesLoading] = useState<boolean>(false);
  const [machinesChangeLoading, setMachinesChangeLoading] = useState<boolean>(false);
  const [machines, setMachines] = useState<IMachine[]>([]);
  const [productSizeActionLoading, setProductSizeActionLoading] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState<IMachine | null>(null);

  // Log out action from Auth context to use in privateErrors
  const { logoutAdminApiAction } = useAdminAuthContext();
  const { currentInstitution } = useInstitutionsContext();

  /**
   * Machines Api Actions
   * Get all
   * Crete
   * Update
   * Delete
   */
  const getAllMachinesApiAction = useCallback(
    (institutionId: string) => {
      return new Promise<void>((resolve) => {
        if (currentInstitution?._id !== institutionId) {
          setMachinesLoading(true);
          getAllMachinesApi(institutionId)
            .then((res) => {
              setMachines(res.data);
            })
            .catch((err: ICommonApiError) => {
              const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
              showErrorToastAction({
                message: data?.message || error || "Failed to fetch machines",
              });
            })
            .finally(() => {
              setMachinesLoading(false);
              resolve();
            });
        }
      });
    },
    [logoutAdminApiAction, currentInstitution]
  );

  const createMachineApiAction = (institutionId: string, createData: IMachineCreateUpdate) => {
    return new Promise<void>((resolve) => {
      setMachinesChangeLoading(true);
      createMachineApi(institutionId, createData)
        .then((res) => {
          setMachines((prev) => [...prev, res.data]);
          showToastAction({ type: "success", message: "Machine created successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to create machine",
          });
        })
        .finally(() => {
          setMachinesChangeLoading(false);
          resolve();
        });
    });
  };

  const updateMachineApiAction = (
    institutionId: string,
    machineId: string,
    editData: IMachineCreateUpdate
  ) => {
    return new Promise<void>((resolve) => {
      setMachinesChangeLoading(true);
      updateMachineApi(institutionId, machineId, editData)
        .then((res) => {
          setMachines((prev) => {
            const index = prev.findIndex((machine) => machine._id === machineId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
          showToastAction({ type: "success", message: "Machine updated successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to update machine",
          });
        })
        .finally(() => {
          setMachinesChangeLoading(false);
          resolve();
        });
    });
  };

  const deleteMachineApiAction = (institutionId: string, machineId: string) => {
    return new Promise<void>((resolve) => {
      setMachinesChangeLoading(true);
      deleteMachineApi(institutionId, machineId)
        .then(() => {
          setMachines((prev) => {
            const filteredData = prev.filter((machine) => machine._id !== machineId);
            return [...filteredData];
          });
          showToastAction({ type: "success", message: "Machine deleted successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to delete machine",
          });
        })
        .finally(() => {
          setMachinesChangeLoading(false);
          resolve();
        });
    });
  };

  const setCurrentMachine = (physicalId: string): void => {
    setSelectedMachine(machines.find((machine) => machine.physicalId === physicalId) ?? null);
  };

  const addSizeApiAction = (institutionId: string, machineId: string, data: IProductSize) => {
    return new Promise<void>((resolve) => {
      setProductSizeActionLoading(true);
      addSizeApi(institutionId, machineId, data)
        .then((res) => {
          setMachines((prev) => {
            const index = prev.findIndex((machine) => machine._id === machineId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
          setSelectedMachine(res.data);
          showToastAction({ type: "success", message: "A new product size added successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to add product size",
          });
        })
        .finally(() => {
          setProductSizeActionLoading(false);
          resolve();
        });
    });
  };

  const updateSizeApiAction = (
    institutionId: string,
    machineId: string,
    sizeId: string,
    data: IProductSize
  ) => {
    return new Promise<void>((resolve) => {
      setProductSizeActionLoading(true);
      updateSizeApi(institutionId, machineId, sizeId, data)
        .then((res) => {
          setMachines((prev) => {
            const index = prev.findIndex((machine) => machine._id === machineId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
          setSelectedMachine(res.data);
          showToastAction({ type: "success", message: "Product size updated successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to update product size",
          });
        })
        .finally(() => {
          setProductSizeActionLoading(false);
          resolve();
        });
    });
  };

  const deleteSizeApiAction = (institutionId: string, machineId: string, sizeId: string) => {
    return new Promise<void>((resolve) => {
      setProductSizeActionLoading(true);
      deleteSizeApi(institutionId, machineId, sizeId)
        .then((res) => {
          setMachines((prev) => {
            const index = prev.findIndex((machine) => machine._id === machineId);
            const newData = [...prev];
            newData[index] = res.data;
            return [...newData];
          });
          setSelectedMachine(res.data);
          showToastAction({ type: "success", message: "A new product size removed successfully" });
        })
        .catch((err: ICommonApiError) => {
          const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
          showErrorToastAction({
            message: data?.message || error || "Failed to remove product size",
          });
        })
        .finally(() => {
          setProductSizeActionLoading(false);
          resolve();
        });
    });
  };

  const contextProps = {
    machinesLoading,
    machinesChangeLoading,
    machines,
    currentMachine: selectedMachine,
    setCurrentMachine,
    getAllMachinesApiAction,
    createMachineApiAction,
    updateMachineApiAction,
    deleteMachineApiAction,
    productSizeActionLoading,
    addSizeApiAction,
    updateSizeApiAction,
    deleteSizeApiAction,
  };

  return <MachineContext.Provider value={contextProps}>{props.children}</MachineContext.Provider>;
};

export default MachinesProvider;

export const useMachineContext = () => {
  const context = useContext(MachineContext);
  if (!context) {
    throw new Error("useMachineContext must be used within a MachineProvider");
  }
  return context;
};
