import React from "react";
import { TimePicker } from "antd";
import { Moment } from "moment";
import styled from "styled-components";
import { RangeValue } from "rc-picker/lib/interface";

const { RangePicker } = TimePicker;

interface IMyProps {
  values?: RangeValue<Moment> | null | undefined;
  onChange?: (
    name: string | undefined,
    newTime: RangeValue<Moment> | null,
    newTimeString?: [string, string]
  ) => void;
  placeholder?: [string, string];
  disabled?: boolean;
  name?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
}

const StyledTimeRangepicker = styled(RangePicker)`
  padding: 6.5px 11px 6.5px;
  line-height: 25px;
`;

const MyTimeRangepicker: React.FC<IMyProps> = (props) => {
  const format = "hh:mm a";
  const {
    values,
    onChange,
    disabled,
    placeholder,
    className,
    name,
    onFocus,
  } = props;

  const wrapOnChange = (
    newTime: RangeValue<Moment>,
    newTimeString: [string, string]
  ) => {
    if (onChange) {
      onChange(name, newTime, newTimeString);
    }
  };

  return (
    // <RangePicker
    <StyledTimeRangepicker
      use12Hours={true}
      className={className}
      format={format}
      value={values}
      onChange={wrapOnChange}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      onFocus={onFocus}
    />
  );
};

export default MyTimeRangepicker;
