import { Divider } from "antd";
import styled from "styled-components";
import React from "react";

const DividerStyled = styled(Divider)`
  margin: 0;
  color: ${({ theme, style }) =>
    style
      ? style.color
        ? (theme.colors as any)[style.color]
        : theme.colors.placeholderLight
      : null};
`;

interface IProps {
  color?: string;
  type?: "horizontal" | "vertical";
  className?: string;
}

const MyDivider: React.FC<IProps> = (props) => {
  const { color, type, className } = props;

  const style = {
    color,
  };

  return (
    <DividerStyled
      className={className}
      type={type || "vertical"}
      // color={color || "placeholderLight"}
      style={style}
    />
  );
};

export default MyDivider;
