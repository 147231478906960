import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { SelectArrowIcon } from "../../../assets/icons";

const Container = styled.div`
  width: 100%;
  &>div.ant-select{
    width: 100%;
  }
`;

const { Option } = Select;
interface IProps {
  name?: string;
  size?: SizeType;
  options: string[];
  value?: number[];
  disabled?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  onChange?: (name: string | undefined, key: [], value: string[]) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const AutoSuggestMultiSelectComponent: React.FC<IProps> = props => {
  const {
    name,
    size,
    options,
    value,
    onChange,
    onFocus,
    placeholder,
    disabled,
    allowClear
  } = props;
  const val = (value && value.length) ? value.map(i => options[i]) : [];
  const children = [];

  for (let i = 0; i < options.length; i++) {
    children.push(<Option key={i} value={options[i]}>{options[i]}</Option>);
  }

  function handleChange(value: string[], option: any) {
    if (onChange)
      onChange(name, option.map((opt: any) => opt.key), value);
  }

  return (
    <Container>
      <Select
        size={size || "large"}
        suffixIcon={SelectArrowIcon}
        showArrow
        onChange={handleChange}
        onFocus={onFocus}
        value={val}
        mode="multiple"
        placeholder={placeholder}
        disabled={disabled}
        allowClear={allowClear}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        } >
        {children}
      </Select>
    </Container>
  );
};

export default AutoSuggestMultiSelectComponent;