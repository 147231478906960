import { AxiosResponse } from "axios";
import { IProductSize } from "src/types/machine";
import {
  ICreateUpdateOrganization,
  IOrganization,
  IUnlockRFID,
} from "src/types/organization";
import { apiRequest } from "../apiRequest";
import {
  addSize,
  addUnlockRFID,
  createOrganization,
  deleteOrganization,
  deleteSize,
  deleteUnlockRFID,
  getAllOrganizations,
  getOrganizationById,
  updateOrganization,
  updateSize,
  updateUnlockRFID,
} from "./organizationApiUrl";

export function getAllOrganizationsApi() {
  return new Promise<AxiosResponse<IOrganization[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllOrganizations())
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getOrganizationByIdApi(organizationId: string) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .get(getOrganizationById(organizationId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createOrganizationApi(createData: ICreateUpdateOrganization) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .post(createOrganization(), createData)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateOrganizationApi(
  organizationId: string,
  updateData: ICreateUpdateOrganization
) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .put(updateOrganization(organizationId), updateData)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

interface IDeleteResponse {
  message: string;
}

export function deleteOrganizationApi(organizationId: string) {
  return new Promise<AxiosResponse<IDeleteResponse>>((resolve, reject) => {
    apiRequest()
      .delete(deleteOrganization(organizationId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addUnlockRFIDApi(organizationId: string, data: IUnlockRFID) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .patch(addUnlockRFID(organizationId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateUnlockRFIDApi(organizationId: string, rfidId: string, data: IUnlockRFID) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .patch(updateUnlockRFID(organizationId, rfidId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteUnlockRFIDApi(organizationId: string, rfidId: string) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .patch(deleteUnlockRFID(organizationId, rfidId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// TODO: REMOVE

export function addSizeApi(organizationId: string, data: IProductSize) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .patch(addSize(organizationId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSizeApi(organizationId: string, sizeId: string, data: IProductSize) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .patch(updateSize(organizationId, sizeId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteSizeApi(organizationId: string, sizeId: string) {
  return new Promise<AxiosResponse<IOrganization>>((resolve, reject) => {
    apiRequest()
      .patch(deleteSize(organizationId, sizeId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
