import React, { Context, createContext, useCallback, useContext, useState } from "react";
import { ICommonApiError } from "src/api/apiRequest";
import { handlePrivateApiError } from "src/api/errorHandlers";
import { getAllDispensesApi, getSuccessfulDispenseStatsApi } from "../../api/dispense/dispenseApi";
import { IDispense, IDispenseStatByMachineId, IGetAllQueryParams } from "../../types/dispense";
import { useAdminAuthContext } from "../admin/AuthContext";
// import { useInstitutionsContext } from "../institution/InstitutionsContext";
import { showErrorToastAction } from "../toast";

interface IDispenseContextProps {
  dispensesLoading: boolean;
  dispenses: IDispense[];
  count: number;
  dispenseStats: IDispenseStatByMachineId[];
  getAllDispensesApiAction: (institutionId: string, query: IGetAllQueryParams) => Promise<void>;
  getSuccessfulDispenseStatsApiAction: (
    institutionId: string,
    start: string,
    end: string
  ) => Promise<void>;
}

export const DispenseContext = createContext<IDispenseContextProps | undefined>(
  undefined
) as Context<IDispenseContextProps>;

const DispenseProvider: React.FC = (props: any) => {
  const [dispensesLoading, setDispensesLoading] = useState<boolean>(false);
  const [dispenses, setDispenses] = useState<IDispense[]>([]);
  const [count, setCount] = useState<number>(0);
  const [dispenseStats, setDispenseStats] = useState<IDispenseStatByMachineId[]>([]);

  // Log out action from Auth context to use in privateErrors
  const { logoutAdminApiAction } = useAdminAuthContext();
  // const { currentInstitution } = useInstitutionsContext();

  const getAllDispensesApiAction = useCallback(
    (institutionId: string, query: IGetAllQueryParams) => {
      return new Promise<void>((resolve) => {
        if (institutionId) {
          setDispensesLoading(true);
          getAllDispensesApi(institutionId, query)
            .then((res) => {
              setDispenses(res.data.dispenses);
              setCount(res.data.count);
            })
            .catch((err: ICommonApiError) => {
              const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
              showErrorToastAction({
                message: data?.message || error || "Failed to fetch dispenses",
              });
            })
            .finally(() => {
              setDispensesLoading(false);
              resolve();
            });
        }
      });
    },
    [logoutAdminApiAction]
  );

  const getSuccessfulDispenseStatsApiAction = useCallback(
    (institutionId: string, start: string, end: string) => {
      return new Promise<void>((resolve) => {
        if (institutionId) {
          setDispensesLoading(true);
          getSuccessfulDispenseStatsApi(institutionId, start, end)
            .then((res) => {
              setDispenseStats(res.data);
            })
            .catch((err: ICommonApiError) => {
              const { error, data } = handlePrivateApiError(err, logoutAdminApiAction);
              showErrorToastAction({
                message: data?.message || error || "Failed to fetch dispense stats",
              });
            })
            .finally(() => {
              setDispensesLoading(false);
              resolve();
            });
        }
      });
    },
    [logoutAdminApiAction]
  );

  const contextProps = {
    dispensesLoading,
    dispenses,
    getAllDispensesApiAction,
    count,
    dispenseStats,
    getSuccessfulDispenseStatsApiAction,
  };

  return <DispenseContext.Provider value={contextProps}>{props.children}</DispenseContext.Provider>;
};

export default DispenseProvider;

export const useDispenseContext = () => {
  const context = useContext(DispenseContext);
  if (!context) {
    throw new Error("useDispenseContext must be used within a DispenseProvider");
  }
  return context;
};
