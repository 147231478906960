import { SchemaOf } from "yup";

export const yupSchemaWrapper = (schema: SchemaOf<any>) => {
  return async (
    data: any
  ): Promise<{
    isValid: boolean;
    errors: any | null;
  }> => {
    try {
      await schema.validate(data, { abortEarly: false });
      return {
        isValid: true,
        errors: null,
      };
    } catch (yupError) {
      const errors: any = {};
      (yupError as any).inner.forEach((e: any) => {
        errors[e.path] = e.message;
      });
      return {
        isValid: false,
        errors,
      };
    }
  };
};
