import axios, { AxiosError } from "axios";

export type ICommonApiError = AxiosError<{
  message: string;
}>;

const axiosInstance = axios.create();

export function apiRequest() {
  return axiosInstance;
}

export function setAuthorizationApi(token: string) {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = token;
  }
}

export function removeAuthorizationApi() {
  delete axiosInstance.defaults.headers.common["Authorization"];
}
