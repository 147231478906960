import { Modal } from "antd";
import React, { useState } from "react";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading } from "src/components/atoms/displays";
import { GridContainer } from "src/components/atoms/grid";
import { Gap } from "src/components/atoms/spaces";
import { NameCard } from "src/components/molecules/cards";
import { firstLetterToUpperCase } from "src/utils/strings";
import styled from "styled-components";
// import { TextField } from "src/components/atoms/inputs";
import { useHistory } from "react-router";
import { Select } from "src/components/atoms/selects";
import { Heading2 } from "src/components/atoms/texts/heading";
import { Writing1, Writing2 } from "src/components/atoms/texts/writing";
import { TextFieldForm } from "src/components/molecules/inputfields";
import { useInstitutionsContext } from "src/contexts/institution/InstitutionsContext";
import { IInstitution, IInstitutionCreate, institutionCategory } from "src/types/institution";
import {
  validateInstitutionCreate,
  validateInstitutionUpdate,
} from "src/validators/institutionValidator";

const Container = styled.div``;

// const ToggleContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 1rem;
// `;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0.75rem;
  }
`;

const StyledDeleteModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0.75rem;
    }
  }
`;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  institutions: IInstitution[];
  organizationId: string;
}

const Institutions: React.FC<IProps> = ({ institutions, organizationId }) => {
  const {
    institutionsLoading,
    institutionsChangeLoading,
    createInstitutionApiAction,
    updateInstitutionApiAction,
    deleteInstitutionApiAction,
  } = useInstitutionsContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IInstitution;
    type: "create" | "update" | "delete" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<IInstitutionCreate>({
    name: "",
    category: "COMMERCIAL",
    address: "",
    // pointsEnabled: false,
  });

  const [error, setError] = useState<any>({});

  const [limitations, setLimitations] = useState<IInstitutionCreate["limitation"]>({
    daily: undefined,
    weekly: undefined,
    monthly: undefined,
  });

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData({ name: "", category: "COMMERCIAL", address: "" });
    setError({});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // const handleToggle = (n: keyof IInstitutionCreate, bool: boolean) => {
  //   setInputData((prev) => ({ ...prev, [n]: bool }));
  // };

  // const handleChangeLimitations = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.persist();
  //   setLimitations((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // };

  const handleCategoryChange = (value: any) => {
    setInputData((prev) => ({ ...prev, category: value }));
  };

  const handleCreateModal = () => {
    setModal({ visible: true, type: "create" });
  };

  const onCreateInstitution = async () => {
    const { errors, isValid } = await validateInstitutionCreate(inputData);
    if (isValid) {
      setError({});
      await createInstitutionApiAction(organizationId, inputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleUpdateModal = (data: IInstitution) => {
    setModal({ visible: true, data: data, type: "update" });
    setInputData({
      name: data.name,
      category: data.category,
      type: data.type,
      address: data.address,
      // pointsEnabled: data.pointsEnabled,
    });
    setLimitations({
      daily: data.limitation?.daily,
      weekly: data.limitation?.weekly,
      monthly: data.limitation?.monthly,
    });
  };

  const onUpdateInstitution = async () => {
    const newInputData = inputData;
    newInputData["limitation"] = limitations;
    const { errors, isValid } = await validateInstitutionUpdate(newInputData);
    if (isValid && modal.data?._id) {
      setError({});
      await updateInstitutionApiAction(modal.data._id, newInputData);
      handleModalClose();
    } else if (!isValid && errors) {
      setError(errors);
      return;
    }
  };

  const handleDeleteModal = (data: IInstitution) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteInstitution = async () => {
    if (modal.data?._id) {
      await deleteInstitutionApiAction(modal.data._id);
      handleModalClose();
    }
  };

  const history = useHistory();
  const handleClick = (institutionId: string) => {
    history.push(`/institution/${institutionId}`);
  };

  return (
    <Container>
      <StyledModal
        title={firstLetterToUpperCase(modal.type) + " Institution"}
        visible={modal.visible && modal.type !== "delete"}
        confirmLoading={institutionsChangeLoading}
        onOk={
          modal.type === "create"
            ? () => onCreateInstitution()
            : modal.type === "update"
            ? () => onUpdateInstitution()
            : () => null
        }
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          <Writing1>Name</Writing1>
          <TextFieldForm
            placeholder="name"
            name="name"
            value={inputData.name}
            onChange={handleChangeName}
            errorMessage={error["name"]}
          />
          <Gap height={"1rem"} />
          <Writing1>Address</Writing1>
          <TextFieldForm
            placeholder="address"
            name="address"
            value={inputData.address}
            onChange={handleChangeName}
            errorMessage={error["address"]}
          />
          <Gap height={"1rem"} />
          <Writing1>Type</Writing1>
          <TextFieldForm
            placeholder="type"
            name="type"
            value={inputData.type}
            onChange={handleChangeName}
            errorMessage={error["type"]}
          />
          <Gap height={"1rem"} />
          <Writing1>Category</Writing1>
          <Select
            name="category"
            placeholder="category"
            onChange={(_n, _k, v) => handleCategoryChange(v)}
            options={institutionCategory}
            value={institutionCategory.indexOf(inputData.category)}
          />
          <Gap height={"1rem"} />
          {/* <ToggleContainer>
            <Writing1>Points enabled</Writing1>
            <Switch
              checked={inputData.pointsEnabled}
              onChange={(bool) => handleToggle("pointsEnabled", bool)}
            />
          </ToggleContainer>
          <Gap height={"1rem"} /> */}
          {/* <Writing1>Limitations</Writing1>
          <GridContainer>
            <TextField
              type="number"
              placeholder="daily"
              name="daily"
              value={limitations?.daily}
              onChange={handleChangeLimitations}
            />
            <TextField
              type="number"
              placeholder="weekly"
              name="weekly"
              value={limitations?.weekly}
              onChange={handleChangeLimitations}
            />
            <TextField
              type="number"
              placeholder="monthly"
              name="monthly"
              value={limitations?.monthly}
              onChange={handleChangeLimitations}
            />
          </GridContainer> */}
        </>
      </StyledModal>

      <StyledDeleteModal
        visible={modal.visible && modal.type === "delete"}
        title={firstLetterToUpperCase(modal.type) + " Institution"}
        onOk={() => onDeleteInstitution()}
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={institutionsChangeLoading}
      >
        <Writing2>Are you sure you want to delete institution "{modal.data?.name}"</Writing2>
      </StyledDeleteModal>

      <ButtonsContainer>
        {institutions ? (
          <SolidButton onClick={handleCreateModal}>Add Institution</SolidButton>
        ) : null}
      </ButtonsContainer>

      <Gap height={"1.5rem"} />

      {institutionsLoading ? (
        <Loading size="60px" />
      ) : institutions.length ? (
        <GridContainer>
          {institutions.map((institution) => (
            <NameCard
              key={institution._id}
              name={institution.name}
              onClick={() => handleClick(institution._id)}
              onEdit={() => handleUpdateModal(institution)}
              onDelete={() => handleDeleteModal(institution)}
            />
          ))}
        </GridContainer>
      ) : (
        <NotFound>
          <Heading2>No institutions found</Heading2>
        </NotFound>
      )}
    </Container>
  );
};

export default Institutions;
