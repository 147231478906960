import React, { PropsWithChildren } from "react";
import { DatePicker } from "antd";
import { Moment } from "moment";
import styled from "styled-components";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { RangeValue } from "rc-picker/lib/interface";

const { RangePicker } = DatePicker;

// export type EventValue<DateType> = DateType | null
// export type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null

interface IMyProps {
  values?: RangeValue<Moment> | null | undefined;
  onChange?: (
    name: string | undefined,
    newDate: RangeValue<Moment> | null,
    newDateString: [string, string]
  ) => void;
  placeholder?: [string, string];
  disabled?: boolean;
  size?: SizeType;
  name?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
}

const StyledDateRangepicker = styled(RangePicker)`
  flex: 1;
`;

const MyDateRangepicker: React.FC<IMyProps> = (props) => {

  const format = "DD/MM/YYYY";
  const { values, onChange, size, disabled, placeholder, className, name, onFocus } = props;

  const wrapOnChange = (newDate: RangeValue<Moment>, newDateString: [string, string]) => {
    if (onChange) {
      onChange(name, newDate, newDateString);
    }
  };

  return (
    <StyledDateRangepicker
      // <RangePicker
      className={className}
      size={size}
      format={format}
      value={values}
      onChange={wrapOnChange}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      onFocus={onFocus}
    />
  );
};

const areEqual = (
  prevProps: Readonly<PropsWithChildren<IMyProps>>,
  nextProps: Readonly<PropsWithChildren<IMyProps>>
) => {
  return (
    prevProps.values === nextProps.values &&
    prevProps.size === nextProps.size &&
    prevProps.disabled === nextProps.disabled &&
    // prevProps.placeholder === nextProps.placeholder &&
    prevProps.name === nextProps.name
    // &&
    // prevProps.onChange === nextProps.onChange 
  );
};

// export default MyDateRangepicker;
// export { RangeValue } from "rc-picker/lib/interface";

export default React.memo(MyDateRangepicker, areEqual);