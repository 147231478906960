import React from "react";
import { ShadowContainer } from "../../../components/atoms/grid";
import styled from "styled-components";
import NavUserDropdown from "../../../components/molecules/navItems/NavUserDropdown";
import LOGO from "../../../assets/images/logo.png";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import { Link } from "react-router-dom";

const LogoImage = styled.img`
  height: 50px;
  width: auto;
`;

const Container = styled.header`
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bodyColor};
`;

const MyShadowContainer = styled(ShadowContainer)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
`;

const HeaderComp: React.FC<{ noAddPage?: boolean }> = () => {
  const { adminAuth, logoutAdminApiAction } = useAdminAuthContext();
  return (
    <MyShadowContainer>
      <Container>
        <Link to={"/"}>
          <LogoImage alt="VMS" src={LOGO} />
        </Link>
        <NavUserDropdown
          name={adminAuth?.admin?.username}
          image={adminAuth?.admin?.avatar}
          onLogout={logoutAdminApiAction}
        />
      </Container>
    </MyShadowContainer>
  );
};

const Header = React.memo(HeaderComp);

export { Header };
