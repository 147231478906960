import * as yup from "yup";
import { yupSchemaWrapper } from "./validatorHelpers";

export const validateAdminCreate = yupSchemaWrapper(
  yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  })
);

export const validateAdminLogin = yupSchemaWrapper(
  yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  })
);
