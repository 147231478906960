import React from "react";
import styled from "styled-components";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Datepicker } from "../../atoms/dates";
import { Label1, Label2, Label3, Label4 } from "../../atoms/texts/label";
import { Message } from "../texts/message";
import { Moment } from "moment";

const DatePickerContainer = styled.div`
  display:flex;
  flex-direction:column;
  margin:1em 0em;
  padding:0;
`;

const LabelContainer = styled.div`
  display:flex;
  padding-bottom:0.5em;
`;

const PickerContainer = styled.div`
  display:flex;
`;

const ErrorContainer = styled.div`
   padding-top:0.2em;
`;

interface IMyProps {
  labelText?: string;
  labelSize?: number;
  errorMessage?: string;
  value?: Moment | null | undefined;
  onChange?: (
    name: string | undefined, newDate: Moment | null, newDateString: string) => void | undefined;
  size?: SizeType;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const MyDatePicker: React.FC<IMyProps> = (props) => {
  const {
    labelText,
    labelSize,
    errorMessage,
    value,
    onChange,
    disabled,
    size,
    placeholder,
    name,
    onFocus
  } = props;

  const Label = labelSize === 1 ? Label1
    : labelSize === 3 ? Label3
      : labelSize === 4 ? Label4
        : Label2;

  return (
    <DatePickerContainer>
      <LabelContainer>
        <Label>{labelText}</Label>
      </LabelContainer>
      <PickerContainer>
        <Datepicker
          size={size || "large"}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          onFocus={onFocus}
        />
      </PickerContainer>
      {
        errorMessage ?
          <ErrorContainer >
            <Message type="error" message={errorMessage} />
          </ErrorContainer>
          : null
      }
    </DatePickerContainer>
  );
};

export default MyDatePicker; 