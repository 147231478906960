import styled from "styled-components";

const Label1 = styled.p`
  font-size: 1em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0em;
`;

const Label2 = styled.p`
  font-size: 1em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0em;
`;

const Label3 = styled.p`
  font-size: 0.875em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0em;
`;

const Label4 = styled.p`
  font-size: 0.875em;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.writingColor};
  margin-bottom: 0em;
`;

export { Label1, Label2, Label3, Label4 };