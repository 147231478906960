export const theme = {
  colors: {
    primaryColor: "#E12F89",
    primaryLight: "#ED82B8",
    primaryDark: "#CB2A7A",
    secondaryColor: "#794092",
    divaColor: "#4C002A",
    dangerColor: "#F44336",
    dangerLight: "#FFE7E7",
    dangerDark: "#AA2E25",
    successColor: "#2ECC71",
    successLight: "#EFFFE8",
    successDark: "#208E4F",
    warningColor: "#FBBF24",
    warningLight: "#FCD34D",
    warningDark: "#F59E0B",
    placeholderText: "#999999",
    placeholderLight: "#DBDBDB",
    placeholderDark: "#707070",
    bodyColor: "#FFFFFF",
    backgroundColor: "#FBFBFF",
    shadowColor: "rgb(237, 130, 184,  0.12)",
    inputShadowColor: "rgba(72, 160, 172, 0.2)",
    placeholderColor: "#999999",
    writingColor: "#3F4451",
    paragraphColor: "#3F4451",
    headingColor: "#263B5E",
    disabledColor: "rgb(0, 0, 0, 0.25)",
    disabledBackground: "#F5F5F5",
    borderDark: "#000000",
    borderLight: "rgba(234, 234, 234, 0.8)",
    borderColor: "#D9D9D9",
    facebookColor: "#0084FF",
    facebookTitle: "#171717",
    facebookSubtitle: "rgba(0, 0, 0, 0.4)",
    facebookBorder: "#F6F6F6",
    upcomingColor: "#FECA57",
    inProgressColor: "#2709DC",
    landingPageBackground: "rgba(225,244,254,1)",
    gray4: "#E8E8E8",
    gray8: "#595959",
    gray7: "#8C8C8C",
    gray9: "#262626",
    boxShadowColor: "rgba(119, 124, 124, 0.06)",
    boxBorderColor: "#F0F0F0",
    dividerColor: "#C4C4C4",
    footerBgColor: "rgba(219, 219, 219, 0.2)",
  },
  statusColors: {
    active: "#52C41A",
    inactive: "#F5222D",

    promo: "#ebc766",
    discount: "#adeccb",

    "order-active": "#49a69d",
    "order-completed": "#52C41A",
    "order-cancelled": "#F5222D",

    ordered: "#FFE7E7",
    received: "#d3ebfa",
    cancelled: "#eea4ad",

    free: "#e4fcda",
    delivering: "#d3ebfa",
    picking: "#FFE7E7",
    waiting: "#EFFFE8",

    accepted: "#e4fcda",
    served: "#EFFFE8",
    returned: "#EFFFE8",

    pending: "#f6bb7b",
    approved: "#e4fcda",

    picked: "#FFE7E7",
    delivered: "#d3ebfa",
    failed: "#eea4ad",
    dropped: "#eea4ad",
  },
  breakpoints: {
    xs: "(min-width: 0px)",
    sm: "(min-width: 600px)",
    md: "(min-width: 960px)",
    lg: "(min-width: 1280px)",
    xl: "(min-width: 1920px)",
  },
};

type TTheme = typeof theme;

export interface ITheme extends TTheme {
  additional: any;
}
