import { AxiosResponse } from "axios";
import { IAdmin, ICreateAdmin, IInstitutionAdmin, IOrganizationAdmin } from "src/types/admin";
import { apiRequest } from "../apiRequest";
import {
  ADMIN_LOGIN,
  createInstitutionAdmin,
  createOrganizationAdmin,
  deleteInstitutionAdmin,
  deleteOrganizationAdmin,
  getAllInstitutionAdmins,
  getAllOrganizationAdmins,
} from "./adminApiUrl";

export interface ILoginInfo {
  username: string;
  password: string;
}

interface ILoginAdminData {
  accessToken: string;
  admin: IAdmin;
}

export function loginAdminApi(data: ILoginInfo) {
  return new Promise<AxiosResponse<ILoginAdminData>>((resolve, reject) => {
    apiRequest()
      .post(ADMIN_LOGIN(), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllOrganizationAdminsApi(organizationId: string) {
  return new Promise<AxiosResponse<IOrganizationAdmin[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllOrganizationAdmins(organizationId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllInstitutionAdminsApi(institutionId: string) {
  return new Promise<AxiosResponse<IInstitutionAdmin[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllInstitutionAdmins(institutionId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createOrganizationAdminApi(organizationId: string, data: ICreateAdmin) {
  return new Promise<AxiosResponse<IOrganizationAdmin>>((resolve, reject) => {
    apiRequest()
      .post(createOrganizationAdmin(organizationId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteOrganizationAdminApi(organizationId: string, adminId: string) {
  return new Promise<AxiosResponse<{ message: string }>>((resolve, reject) => {
    apiRequest()
      .delete(deleteOrganizationAdmin(organizationId, adminId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createInstitutionAdminApi(institutionId: string, data: ICreateAdmin) {
  return new Promise<AxiosResponse<IInstitutionAdmin>>((resolve, reject) => {
    apiRequest()
      .post(createInstitutionAdmin(institutionId), data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteInstitutionAdminApi(institutionId: string, adminId: string) {
  return new Promise<AxiosResponse<{ message: string }>>((resolve, reject) => {
    apiRequest()
      .delete(deleteInstitutionAdmin(institutionId, adminId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
