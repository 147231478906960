import React from "react";
import { Tooltip } from "../../../atoms/displays";
import { InfoCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

interface IProps {
  tooltip: string;
  className?: string;
}

const IconWrap = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const IconTooltip: React.FC<IProps> = (props) => {
  return (
    <div className={props.className}>
      <Tooltip tooltip={props.tooltip}>
        <IconWrap />
      </Tooltip>
    </div>
  );
};

export { IconTooltip };