import React from "react";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const { Option } = Select;
interface IProps {
  name?: string;
  size?: SizeType;
  options: string[];
  value?: number[];
  disabled?: boolean;
  placeholder?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (name: string | undefined, key: number[]) => void;
}

const MultiSelectComponent: React.FC<IProps> = props => {
  const {
    name,
    size,
    options,
    value,
    onChange,
    onFocus,
    placeholder,
    disabled
  } = props;
  const val = (value && value.length) ? value.map(i => options[i]) : [];
  const children = [];

  for (let i = 0; i < options.length; i++) {
    children.push(<Option key={i} value={options[i]}>{options[i]}</Option>);
  }

  function handleChange(value: any[], option: any) {
    if (onChange) onChange(name, option.map((opt: any) => opt.key));
  }

  return (
    <Select
      size={size || "large"}
      showArrow
      onChange={handleChange}
      onFocus={onFocus}
      value={val}
      allowClear
      mode="multiple"
      placeholder={placeholder}
      disabled={disabled}
      style={{ width: "100%" }} >
      {children}
    </Select>
  );
};

export default MultiSelectComponent;