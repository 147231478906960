import React from "react";
import AuthContextProvider from "./admin/AuthContext";
import InstitutionsProvider from "./institution/InstitutionsContext";
import MachinesProvider from "./machine/MachineContext";
import OrganizationsProvider from "./organization/OrganizationsContext";
import RFIDProvider from "./rfid/RFIDContext";
import DispenseProvider from "./dispense/DispenseContext";

const RootProvider: React.FC = (props: any) => {
  return (
    <AuthContextProvider>
      <OrganizationsProvider>
        <InstitutionsProvider>
          <MachinesProvider>
            <DispenseProvider>
              <RFIDProvider>{props.children}</RFIDProvider>
            </DispenseProvider>
          </MachinesProvider>
        </InstitutionsProvider>
      </OrganizationsProvider>
    </AuthContextProvider>
  );
};

export default RootProvider;
