import React, { useEffect, useState } from "react";

interface IProps {
  numberOfDots?: number;
}

const DotLoading: React.FC<IProps> = ({ numberOfDots }) => {
  const [dots, setDots] = useState<number>(numberOfDots || 0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev < 4) return prev + 1;
        else return 0;
      });
    }, 200);
    return () => clearInterval(interval);
  }, []);
  return <span>{Array.from(Array(dots), () => ".")}</span>;
};

export default DotLoading;
