import React from "react";
import { Button } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import styled from "styled-components";

const ButtonStyled = styled(Button)`
  border:none;
  box-shadow:none;
  -webkit-box-shadow:none;
  background: transparent;

  :hover {
    background: transparent;
  }
`;

interface IProps {
  size?: SizeType;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const FlatButton: React.FC<IProps> = props => {
  const { size, onClick, loading, loadingText, disabled, className } = props;
  return (
    <ButtonStyled
      className={className}
      disabled={disabled} onClick={onClick} size={size || "large"} loading={loading}>
      {loading ?
        loadingText
        :
        props.children
      }
    </ButtonStyled>
  );
};

export default FlatButton;
