import React from "react";
import { Input } from "antd";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  .ant-input-textarea {
    width: 100%;
  }
`;

interface IMyProps {
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  rows?: number;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: () => void;
  allowClear?: boolean;
}

const TextArea: React.FC<IMyProps> = (props) => {
  const {
    defaultValue,
    value,
    disabled,
    placeholder,
    maxLength,
    id,
    name,
    rows,
    onChange,
    onFocus,
    onPressEnter,
    allowClear,
  } = props;
  return (
    <Container>
      <Input.TextArea
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        name={name}
        rows={rows}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={onFocus}
        onPressEnter={onPressEnter}
        allowClear={allowClear}
      />
    </Container>
  );
};

export default TextArea;
