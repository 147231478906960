import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Writing4, Writing5 } from "../../atoms/texts/writing";
import { LogoutIcon } from "../../../assets/icons";
import { Avatar } from "../../atoms/displays";

interface IProps {
  name?: string;
  image?: string;
  onLogout?: () => void;
}

const MyMenu = styled(Menu)`
  border-radius: 5px;
  width: 145px;
  padding: 0;
`;

const MyAnchor = styled.a`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`;
const MenuItem = styled(Menu.Item)``;

const Wrapper = styled.div`
  padding: 0.25em;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const WritingName = styled(Writing4)`
  padding-left: 6px;
`;

const DownIcon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.borderColor};
  padding: 0 5px;
`;

const WelcomeMenuItem = styled(Menu.Item)`
  cursor: auto;
`;

const NavUserDropdown: React.FC<IProps> = (props) => {
  const { name, image, onLogout } = props;

  const handleClick = (e: any) => {
    if (e.key === "2") {
      if (onLogout) {
        onLogout();
      }
    }
  };

  const menu = (
    <MyMenu onClick={handleClick}>
      <WelcomeMenuItem key="1">
        <Writing5>Welcome!</Writing5>
      </WelcomeMenuItem>
      <MenuItem key="2">
        <Wrapper>
          <LogoutIcon />
          <Writing4>Logout</Writing4>
        </Wrapper>
      </MenuItem>
    </MyMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
      <MyAnchor>
        <Avatar src={image} />
        <WritingName>{name}</WritingName>
        <DownIcon />
      </MyAnchor>
    </Dropdown>
  );
};

export default NavUserDropdown;
