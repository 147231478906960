import * as yup from "yup";
import { yupSchemaWrapper } from "./validatorHelpers";

export const validateOrganization = yupSchemaWrapper(
  yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    type: yup.string(),
  })
);

export const validateUnlockRFID = yupSchemaWrapper(
  yup.object().shape({
    physicalId: yup.string().required("Physical Id is required"),
    name: yup.string(),
    isActive: yup.boolean().required("RFID activity is required"),
  })
);

export const validateProductSize = yupSchemaWrapper(
  yup.object().shape({
    sizeName: yup.string().required("Size name is required"),
    sizeId: yup.string().required("Size Id is required"),
    price: yup.number().typeError("Price must be a number"),
    point: yup.number().typeError("Point must be a number"),
  })
);
