import React, { ReactNode } from "react";
import { Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";


interface IMyProps {
  size?: SizeType;
  defaultValue?: string;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  type?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPressEnter?: () => void;
  allowClear?: boolean;
  className?: string;
}

const TextField: React.FC<IMyProps> = (props) => {
  const { size, defaultValue, value, disabled, placeholder, className, maxLength,
    id, name, type, prefix, suffix, onChange, onFocus, onPressEnter, allowClear } = props;
  return (
    <Input
      size={size || "large"}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      id={id}
      type={type}
      name={name}
      prefix={prefix}
      suffix={suffix}
      maxLength={maxLength}
      onChange={onChange}
      onFocus={onFocus}
      onPressEnter={onPressEnter}
      className={className}
      allowClear={allowClear} />
  );
};

export default TextField;