import React from "react";
import { TimePicker } from "antd";
import { Moment } from "moment";
import styled from "styled-components";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface IMyProps {
  value?: Moment | null | undefined;
  onChange?: (
    name: string | undefined,
    newDate: Moment | null,
    newDateString?: string
  ) => void | undefined;
  placeholder?: string;
  disabled?: boolean;
  size?: SizeType;
  name?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const StyledTimepicker = styled(TimePicker)`
  flex: 1;
`;

const MyTimepicker: React.FC<IMyProps> = (props) => {

  const format = "hh:mm a";
  const { value, onChange, size, disabled, placeholder, name, onFocus } = props;

  const wrapOnChange = (newDate: Moment | null, newDateString: string) => {
    if (onChange) {
      onChange(name, newDate, newDateString);
    }
  };

  const onSelect = (value: Moment) => {
    if (onChange) {
      onChange(name, value);
    }
  };

  return (
    // <TimePicker
    <StyledTimepicker
      use12Hours={true}
      size={size}
      format={format}
      value={value}
      onChange={wrapOnChange}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      onFocus={onFocus}
      onSelect={onSelect}
    />
  );
};

// const areEqual = (
//   prevProps: Readonly<PropsWithChildren<IMyProps>>,
//   nextProps: Readonly<PropsWithChildren<IMyProps>>
// ) => {
//   return (
//     prevProps.value === nextProps.value &&
//     prevProps.size === nextProps.size &&
//     prevProps.disabled === nextProps.disabled &&
//     // prevProps.placeholder === nextProps.placeholder &&
//     prevProps.name === nextProps.name
//     // &&
//     // prevProps.onChange === nextProps.onChange 
//   );
// };

// export default React.memo(MyTimepicker, areEqual);
export default MyTimepicker;
