import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Landing } from "../../components/templates/LandingPage";
import {
  SUPER_ADMIN_DASHBOARD,
  HOME,
  ORGANIZATION_ADMIN_DASHBOARD,
  INSTITUTION_ADMIN_DASHBOARD,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_FAILURE,
  PAYMENT_PROCESS_FAILED,
} from "../../constants/routes";
import styled from "styled-components";
import { PrivateRoute } from "./PrivateRoute";
import DashboardLayout from "../../components/layouts/Dashboard";
import { ADMIN_TYPE } from "../../types/admin";
import SuperAdminPage from "../../components/templates/SuperAdmin";
import OrganizationAdminPage from "src/components/templates/organizationAdmin";
import InstitutionAdminPage from "src/components/templates/institutionAdmin";
import PaymentStatusSuccessPage from "../../components/templates/payment/StatusSuccessPage";
import PaymentStatusFailurePage from "../../components/templates/payment/StatusFailurePage";
import PaymentProcessFailurePage from "../../components/templates/payment/ProcessFailurePage";

const NotFoundContainer = styled.div`
  text-align: center;
  font-size: 0.75rem;
  padding: 2rem;
`;

const Routers: React.FC = () => {
  const { SUPER, ORGANIZATION, INSTITUTION } = ADMIN_TYPE;
  return (
    <Router>
      <Switch>
        <Route exact={true} path={HOME()}>
          <Landing />
        </Route>
        <PrivateRoute
          component={SuperAdminPage}
          layout={DashboardLayout}
          exact={true}
          path={SUPER_ADMIN_DASHBOARD()}
          adminTypes={[SUPER]}
        />
        <PrivateRoute
          component={OrganizationAdminPage}
          layout={DashboardLayout}
          exact={true}
          path={ORGANIZATION_ADMIN_DASHBOARD(":organizationId")}
          adminTypes={[SUPER, ORGANIZATION]}
        />
        <PrivateRoute
          component={InstitutionAdminPage}
          layout={DashboardLayout}
          exact={true}
          path={INSTITUTION_ADMIN_DASHBOARD(":institutionId")}
          adminTypes={[SUPER, ORGANIZATION, INSTITUTION]}
        />

        <Route exact={true} path={PAYMENT_STATUS_SUCCESS} component={PaymentStatusSuccessPage} />
        <Route exact={true} path={PAYMENT_STATUS_FAILURE} component={PaymentStatusFailurePage} />
        <Route exact={true} path={PAYMENT_PROCESS_FAILED} component={PaymentProcessFailurePage} />

        <Route exact={true} path="*">
          <NotFoundContainer>route not found</NotFoundContainer>
        </Route>
      </Switch>
    </Router>
  );
};

export { Routers };
