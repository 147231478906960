import React, { useEffect, useState } from "react";
import { Writing1 } from "src/components/atoms/texts/writing";
import { IInstitution } from "src/types/institution";
import { IOrganization } from "src/types/organization";
import styled from "styled-components";
import DotLoading from "./DotLoading";

const Container = styled.div`
  padding: 1rem;
  padding-top: 0;
`;

const LightSpan = styled.span`
  font-weight: 400;
`;

interface IProps {
  type: "ORGANIZATION" | "INSTITUTION";
  organization?: IOrganization;
  institution?: IInstitution;
}

const Description: React.FC<IProps> = ({ type, organization, institution }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(false);
    if (organization || institution) setLoaded(true);
  }, [organization, institution]);

  // const renderLimitations = (limitations: IInstitution["limitation"]) => {
  //   let str = "";
  //   if (limitations?.daily) {
  //     str += `${limitations?.daily}(daily)`;
  //   }
  //   if (limitations?.weekly) {
  //     if (limitations?.daily) str += ", ";
  //     str += `${limitations?.daily}(weekly)`;
  //   }
  //   if (limitations?.monthly) {
  //     if (limitations?.daily || limitations?.weekly) str += ", ";
  //     str += `${limitations.monthly}(monthly)`;
  //   }
  //   if (!limitations?.daily && !limitations?.weekly && !limitations?.monthly) {
  //     str = "N/A";
  //   }
  //   return str;
  // };

  return (
    <Container>
      {type === "ORGANIZATION" ? (
        <>
          <Writing1>
            <LightSpan>Organization name: </LightSpan> {organization?.name || <DotLoading />}
          </Writing1>
          <Writing1>
            <LightSpan>Description: </LightSpan> {organization?.description || <DotLoading />}
          </Writing1>
          <Writing1>
            <LightSpan>Type: </LightSpan> {loaded ? organization?.type || "N/A" : <DotLoading />}
          </Writing1>
        </>
      ) : (
        <>
          <Writing1>
            <LightSpan>Institution name: </LightSpan> {institution?.name || <DotLoading />}
          </Writing1>
          <Writing1>
            <LightSpan>Address: </LightSpan> {institution?.address || <DotLoading />}
          </Writing1>
          {/* <Writing1>
            <LightSpan>Limit: </LightSpan>
            {loaded ? renderLimitations(institution?.limitation) : <DotLoading />}
          </Writing1> */}
        </>
      )}
    </Container>
  );
};

export default Description;
