import React, { memo } from "react";
import MoreOutlined from "@ant-design/icons/lib/icons/MoreOutlined";
import { Dropdown, Menu } from "antd";
import styled from "styled-components";

import { ShadowContainer } from "../../atoms/grid";
import { Writing2 } from "../../atoms/texts/writing";

const NameWriting = styled(Writing2)`
  margin-right: 0.25rem;
  cursor: pointer;
  transition: 0.3s ease-out;
  :hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
`;

const CustomDropDown = styled(Dropdown)`
  margin-top: 0.3rem;
`;

const MoreIcon = styled(MoreOutlined)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface IProps {
  name: string;
  onClick?: (data: any) => void;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
}
const NameCard: React.FC<IProps> = memo((props) => {
  const { name, onClick, onEdit, onDelete } = props;

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "1": {
        onEdit && onEdit(name);
        break;
      }
      case "2": {
        onDelete && onDelete(name);
        break;
      }
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {onEdit ? <Menu.Item key="1">edit</Menu.Item> : null}
      {onDelete ? <Menu.Item key="2">delete</Menu.Item> : null}
    </Menu>
  );
  return (
    <ShadowContainer rounded={true}>
      <CardContainer>
        <NameWriting onClick={onClick}>{name}</NameWriting>
        <CustomDropDown overlay={menu} trigger={["click"]} placement="bottomLeft">
          <MoreIcon />
        </CustomDropDown>
      </CardContainer>
    </ShadowContainer>
  );
});
export default NameCard;
