export interface IDispense {
  _id: string;
  machineId: string;
  institutionId: string;
  organizationId: string;
  rfid: string;
  transactionType: string;
  paid?: boolean;
  isSuccessful: boolean;
  dispensedAt: Date;
  failureReason: string;
}

export interface IDispenseGetAllResponse {
  dispenses: IDispense[];
  count: number;
}

export interface IDispenseStatByMachineId {
  machines: {
    machineId: string;
    count: number;
  }[];
  type: string;
}

export const TRANSACTION_TYPE = {
  CASH: "CASH" as const,
  RFID: "RFID" as const,
  BKASH: "BKASH" as const,
};
export const transactionTypes = Object.values(TRANSACTION_TYPE);
export type TTransactionType = typeof transactionTypes[number];

export const GET_ALL_QUERY_STATUS = {
  ALL: "ALL" as const,
  SUCCESSFUL: "SUCCESSFUL" as const,
  FAILED: "FAILED" as const,
};
export const getAllQueryStatus = Object.values(GET_ALL_QUERY_STATUS);
export type TGetAllQueryStatus = typeof getAllQueryStatus[number];

export const GET_ALL_QUERY_TRANSACTION_TYPE = {
  ALL: "ALL" as const,
  CASH: "CASH" as const,
  RFID: "RFID" as const,
  BKASH_ALL: "BKASH_ALL" as const,
  BKASH_PAID: "BKASH_PAID" as const,
  BKASH_UNPAID: "BKASH_UNPAID" as const,
};
export const getAllQueryTransactionTypes = Object.values(GET_ALL_QUERY_TRANSACTION_TYPE);
export type TGetAllQueryTransactionType = typeof getAllQueryTransactionTypes[number];

export interface IGetAllQueryParams {
  page: number;
  limit: number;
  startDate: Date;
  endDate: Date;
  machineId?: string;
  status: TGetAllQueryStatus;
  transactionType: TGetAllQueryTransactionType;
}
