import React, { ReactNode } from "react";
import styled from "styled-components";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { PasswordField } from "../../atoms/inputs";
import { Label1, Label2, Label3, Label4 } from "../../atoms/texts/label";
import { Message } from "../texts/message";

const PasswordFieldContainer = styled.div`
  display:flex;
  flex-direction:column;
  padding:0;
`;

const LabelContainer = styled.div`
  display:flex;
  padding-bottom:0.5em;
`;

const FieldContainer = styled.div`
  display:flex;
`;

const ErrorContainer = styled.div`
   padding-top:0.2em;
`;

interface IMyProps {
  labelText?: string;
  labelSize?: number;
  errorMessage?: string;
  size?: SizeType;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPressEnter?: () => void;
  visibilityToggle?: boolean;
}

const MyPasswordFieldForm: React.FC<IMyProps> = (props) => {
  const {
    labelText,
    labelSize,
    errorMessage,
    size,
    value,
    disabled,
    placeholder,
    id,
    name,
    prefix,
    suffix,
    onChange,
    onFocus,
    onPressEnter,
    visibilityToggle
  } = props;

  const Label = labelSize === 1 ? Label1
    : labelSize === 3 ? Label3
      : labelSize === 4 ? Label4
        : Label2;

  return (
    <PasswordFieldContainer>
      <LabelContainer>
        <Label>{labelText}</Label>
      </LabelContainer>
      <FieldContainer>
        <PasswordField
          size={size || "large"}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          name={name}
          prefix={prefix}
          suffix={suffix}
          onChange={onChange}
          onFocus={onFocus}
          onPressEnter={onPressEnter}
          visibilityToggle={visibilityToggle}
        />
      </FieldContainer>
      {
        errorMessage ?
          <ErrorContainer >
            <Message type="error" message={errorMessage} />
          </ErrorContainer>
          : null
      }
    </PasswordFieldContainer >
  );
};

export default MyPasswordFieldForm; 