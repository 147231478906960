import React from "react";
import { Redirect } from "react-router";
import {
  INSTITUTION_ADMIN_DASHBOARD,
  ORGANIZATION_ADMIN_DASHBOARD,
  SUPER_ADMIN_DASHBOARD,
} from "src/constants/routes";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import styled from "styled-components";
import { LogIn } from "./LogIn";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const LandingMain: React.FC = () => {
  const { adminAuth } = useAdminAuthContext();

  const renderRoute = () => {
    return adminAuth?.admin?.type === "SUPER" ? (
      <Redirect to={SUPER_ADMIN_DASHBOARD()} />
    ) : adminAuth?.admin?.type === "ORGANIZATION" && adminAuth?.admin.organizationId ? (
      <Redirect to={ORGANIZATION_ADMIN_DASHBOARD(adminAuth?.admin.organizationId)} />
    ) : adminAuth?.admin?.type === "INSTITUTION" && adminAuth?.admin.institutionId ? (
      <Redirect to={INSTITUTION_ADMIN_DASHBOARD(adminAuth?.admin.institutionId)} />
    ) : null;
  };

  return adminAuth?.isAuthenticated ? (
    renderRoute()
  ) : (
    <Container>
      <LogIn />
    </Container>
  );
};

export default LandingMain;
