import React from "react";
import { Button } from "antd";
import styled from "styled-components";

const ButtonStyled = styled(Button)<any>`
  margin: 0 20px;
  padding: 0;
  font-size: 18px;
  color: ${({ theme, active }) =>
    active ? theme.colors.primaryColor : theme.colors.writingColor};
  border: none;
  box-shadow: none;
  background: none;
  :hover,
  :focus {
    background: none;
  }
  text-transform: capitalize;
`;

interface IProps {
  disabled?: boolean;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const TabButton: React.FC<IProps> = (props) => {
  const { onClick, disabled, active } = props;
  return (
    <ButtonStyled
      disabled={disabled}
      onClick={onClick}
      active={active ? "active" : ""}
    >
      {props.children}
    </ButtonStyled>
  );
};

export default TabButton;
