import React from "react";
import { Button } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";

const MyButton = styled(Button) <IProps>`
  transform: ${({ size }) => size === "middle" ? "scale(0.85,0.85)"
    : size === "large" ? "scale(1,1)"
      : "scale(0.7,0.7)"};

`;

interface IProps {
  size?: SizeType;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const CrossButton: React.FC<IProps> = props => {
  const { size, onClick, disabled, className } = props;
  return (
    <div className={className}>
      <MyButton
        size={size || "small"}
        onClick={onClick}
        disabled={disabled}
        danger
        type="primary"
        shape="circle"
        icon={<CloseOutlined />} />
    </div>
  );
};

export default CrossButton;
