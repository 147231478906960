import { BASE_URL } from "src/utils/config";

export const getAllOrganizations = () => `${BASE_URL}/api/v1/organization/all`;

export const getOrganizationById = (organizationId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}`;

export const createOrganization = () => `${BASE_URL}/api/v1/organization/create`;

export const updateOrganization = (organizationId: string) =>
  `${BASE_URL}/api/v1/organization/update/${organizationId}`;

export const deleteOrganization = (organizationId: string) =>
  `${BASE_URL}/api/v1/organization/remove/${organizationId}`;

export const addUnlockRFID = (organizationId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}/unlock/rfid/add`;

export const updateUnlockRFID = (organizationId: string, rfidId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}/unlock/rfid/${rfidId}/update`;

export const deleteUnlockRFID = (organizationId: string, rfidId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}/unlock/rfid/${rfidId}/remove`;

export const addSize = (organizationId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}/size/add`;

export const updateSize = (organizationId: string, sizeId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}/size/${sizeId}/update`;

export const deleteSize = (organizationId: string, sizeId: string) =>
  `${BASE_URL}/api/v1/organization/${organizationId}/size/${sizeId}/remove`;
